async function LogUser(page, summary) {
  const postData = {
    page: page,
    summary: summary,
  };

  try {
    await fetch("https://prosjekt.tkva.no/api/log.php", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(postData),
      credentials: "include",
    });
  } catch (err) {
    return;
  }
}

export default LogUser;
