import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useParams, Link } from "react-router-dom";
import { ModalWrapper } from "../../components/ModalWrapper";

export default function PowerBI() {
  const params = useParams();
  const [type, setType] = useState(null);

  useEffect(() => {
    if (params.type) {
      setType(params.type);
    } else {
      setType(null);
    }
  }, [params]);

  return !type ? (
    <>
      <h4>Velg rapport</h4>
      <div className="d-flex">
        <div className="p-2">
          <ModalWrapper
            triggerItem={<Button>Vann og avløp</Button>}
            title={"Vann og avløp - Power BI - Investeringer"}
            bodyStyle={{
              height: "800px",
            }}
          >
            <iframe
              title="VA-Prosjektoversikt v2"
              style={{ width: "100%", height: "100%" }} // Endre til 100% høyde
              src="https://app.powerbi.com/reportEmbed?reportId=e3ccd7fb-6417-452b-aa9c-8b7e8b263966&autoAuth=true&ctid=91f01968-b32d-4aa6-8d47-da1fdf0e5a59"
              frameBorder={0}
              allowFullScreen="true"
            />
          </ModalWrapper>
        </div>
        <div className="p-2">
          <ModalWrapper
            triggerItem={<Button>Veg</Button>}
            title={"Veg - Power BI - Investeringer"}
            bodyStyle={{
              height: "800px",
            }}
          >
            <iframe
              title="Prosjektoversikt VEG"
              style={{ width: "100%", height: "100%" }} // Endre til 100% høyde
              src="https://app.powerbi.com/reportEmbed?reportId=65aa662d-b3ae-44f5-b685-c26c396de6bf&autoAuth=true&ctid=91f01968-b32d-4aa6-8d47-da1fdf0e5a59"
              frameBorder={0}
              allowFullScreen="true"
            />
          </ModalWrapper>
        </div>
      </div>
    </>
  ) : (
    <>
      <div className="container d-flex">
        <div
          className="d-flex flex-grow-1 h-100"
          style={{ minHeight: "80dvh" }}
        >
          {type === "Vann og avløp" && (
            <iframe
              title="VA-Prosjektoversikt v2"
              style={{ width: "100%", minHeight: "100%" }} // Endre til 100% høyde
              src="https://app.powerbi.com/reportEmbed?reportId=e3ccd7fb-6417-452b-aa9c-8b7e8b263966&autoAuth=true&ctid=91f01968-b32d-4aa6-8d47-da1fdf0e5a59"
              frameBorder={0}
              allowFullScreen="true"
            />
          )}
          {type === "Veg" && (
            <iframe
              title="Prosjektoversikt VEG"
              style={{ width: "100%", height: "100%" }} // Endre til 100% høyde
              src="https://app.powerbi.com/reportEmbed?reportId=65aa662d-b3ae-44f5-b685-c26c396de6bf&autoAuth=true&ctid=91f01968-b32d-4aa6-8d47-da1fdf0e5a59"
              frameBorder={0}
              allowFullScreen="true"
            />
          )}
        </div>
      </div>
    </>
  );
}
