import React, { useRef } from "react";
import { useDrag, useDrop } from "react-dnd";

const style = {
  backgroundColor: "white",
  cursor: "move",
};

export const Avvik_PL_Item = ({
  item,
  index,
  activePL,
  movePL,
  setActivePL,
  moveProject, // Ny funksjon for å flytte prosjekter
  TimelineForYear,
  convertNumber,
  data,
}) => {
  const ref = useRef(null);

  // Dra og slipp for prosjektleder
  const [{ handlerId }, dropPL] = useDrop({
    accept: "PROSJEKTLEDER", // Unik type for prosjektleder
    hover: (draggedItem) => {
      if (draggedItem.index !== index) {
        movePL(draggedItem.index, index);
        draggedItem.index = index;
      }
    },
    collect: (monitor) => ({
      handlerId: monitor.getHandlerId(),
    }),
  });

  const [{ isDraggingPL }, dragPL] = useDrag({
    type: "PROSJEKTLEDER", // Unik type for prosjektleder
    item: { index },
    collect: (monitor) => ({
      isDraggingPL: monitor.isDragging(),
    }),
  });

  // Kombiner dragging for prosjektleder
  dragPL(dropPL(ref));

  return (
    <>
      <tr
        style={{ ...style, opacity: isDraggingPL ? 0.5 : 1 }}
        ref={ref}
        data-handler-id={handlerId}
        onClick={() => setActivePL((prev) => toggleActivePL(prev, item))}
      >
        <th scope="row" colSpan={11}>
          {item.prosjektleder}
        </th>
        {/* Additional columns if needed */}
      </tr>

      {activePL.includes(item.prosjektleder) && (
        <>
          {item.data.map((prosj, projIndex) => (
            <ProjectRow
              key={prosj.prosjektnr}
              prosjekt={prosj}
              prosjektleder={item.prosjektleder}
              projIndex={projIndex}
              moveProject={moveProject}
              TimelineForYear={TimelineForYear}
              convertNumber={convertNumber}
              data={data}
            />
          ))}
        </>
      )}
    </>
  );
};

// Ny komponent for å håndtere prosjektlinjer
const ProjectRow = ({
  prosjekt,
  prosjektleder,
  projIndex,
  moveProject,
  TimelineForYear,
  convertNumber,
  data,
}) => {
  const ref = useRef(null);

  const [{ isDragging }, drag] = useDrag({
    type: "PROJECT", // Unik type for prosjekter
    item: { prosjekt, prosjektleder },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const [, drop] = useDrop({
    accept: "PROJECT", // Unik type for prosjekter
    drop: (draggedItem) => {
      if (draggedItem.prosjektleder !== prosjektleder) {
        moveProject(
          draggedItem.prosjekt,
          draggedItem.prosjektleder,
          prosjektleder
        );
      }
    },
  });

  drag(drop(ref));

  return (
    <tr ref={ref} style={{ opacity: isDragging ? 0.5 : 1 }}>
      <td>
        {prosjekt.prosjektnr} - {prosjekt.prosjektnavn}
      </td>
      <td className="text-center" style={{ padding: "5px 0px 0px 0px" }}>
        <div>{convertNumber(prosjekt.ø1)}</div>
        <TimelineForYear prosjekt={prosjekt.prosjektnr} year={data.year} />
      </td>
      <td className="text-center" style={{ padding: "5px 0px 0px 0px" }}>
        <div>{convertNumber(prosjekt.ø2)}</div>
        <TimelineForYear prosjekt={prosjekt.prosjektnr} year={data.year + 1} />
      </td>
      <td className="text-center" style={{ padding: "5px 0px 0px 0px" }}>
        <div>{convertNumber(prosjekt.ø3)}</div>
        <TimelineForYear prosjekt={prosjekt.prosjektnr} year={data.year + 2} />
      </td>
      <td className="text-center" style={{ padding: "5px 0px 0px 0px" }}>
        <div>{convertNumber(prosjekt.ø4)}</div>
        <TimelineForYear prosjekt={prosjekt.prosjektnr} year={data.year + 3} />
      </td>
      <td className="text-center" style={{ padding: "5px 0px 0px 0px" }}>
        <div>{convertNumber(prosjekt.ø5)}</div>
        <TimelineForYear prosjekt={prosjekt.prosjektnr} year={data.year + 4} />
      </td>
      <td className="text-center" style={{ padding: "5px 0px 0px 0px" }}>
        <div>{convertNumber(prosjekt.ø6)}</div>
        <TimelineForYear prosjekt={prosjekt.prosjektnr} year={data.year + 5} />
      </td>
      <td className="text-center" style={{ padding: "5px 0px 0px 0px" }}>
        <div>{convertNumber(prosjekt.ø7)}</div>
        <TimelineForYear prosjekt={prosjekt.prosjektnr} year={data.year + 6} />
      </td>
      <td className="text-center" style={{ padding: "5px 0px 0px 0px" }}>
        <div>{convertNumber(prosjekt.ø8)}</div>
        <TimelineForYear prosjekt={prosjekt.prosjektnr} year={data.year + 7} />
      </td>
      <td className="text-center" style={{ padding: "5px 0px 0px 0px" }}>
        <div>{convertNumber(prosjekt.ø9)}</div>
        <TimelineForYear prosjekt={prosjekt.prosjektnr} year={data.year + 8} />
      </td>
      <td className="text-center" style={{ padding: "5px 0px 0px 0px" }}>
        <div>{convertNumber(prosjekt.ø10)}</div>
        <TimelineForYear prosjekt={prosjekt.prosjektnr} year={data.year + 9} />
      </td>
    </tr>
  );
};

// Helper funksjon for å toggle aktiv prosjektleder
const toggleActivePL = (prev, item) =>
  prev.includes(item.prosjektleder)
    ? prev.filter((leader) => leader !== item.prosjektleder)
    : [...prev, item.prosjektleder];
