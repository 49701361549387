import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import PropTypes from "prop-types";

export const ModalWrapper = ({
  triggerItem,
  title,
  children,
  style,
  bodyStyle,
}) => {
  const [show, setShow] = useState(false);

  const Trigger = React.cloneElement(triggerItem, {
    onClick: () => setShow(true),
  });

  return (
    <>
      {Trigger}
      <Modal
        size="xl"
        show={show}
        onHide={() => setShow(false)}
        style={style} // Legg til style her
      >
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body style={bodyStyle}>{children}</Modal.Body>
      </Modal>
    </>
  );
};

ModalWrapper.propTypes = {
  triggerItem: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  style: PropTypes.object, // Valgfri stilprop
  bodyStyle: PropTypes.object,
};
