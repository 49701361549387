import React, { useEffect, useState } from "react";

import { Button, Modal } from "react-bootstrap";
import { getCookie, setCookie, removeCookie, getAllCookies } from "./cookie";

export default function CookieConsent() {
  const [loaded, setLoaded] = useState(false);
  const [consent, setConsent] = useState(false);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    getConsent();
  }, []);

  const getConsent = async () => {
    if (getCookie("cookieConsent")) {
      setConsent(getCookie("cookieConsent"));
    }
    setLoaded(true);
  };

  const setCookieConsent = async (val) => {
    const date = new Date();
    date.setFullYear(date.getFullYear() + 1); // Legger til 1 år fra nå

    if (val === true) {
      setConsent(true);
      setCookie("cookieConsent", true, {
        expires: date,
      });
    } else {
      try {
        await fetch("https://prosjekt.tkva.no/api/logout.php", {
          credentials: "include",
        });
      } catch (err) {
        return;
      }
      setConsent(false);
      setCookie("cookieConsent", false, {
        expires: date,
      });
      Object.entries(getAllCookies()).map(([key, value]) => {
        removeCookie(key);
      });
      window.open("about:blank", "_self");
      window.close();
    }
  };

  return (
    loaded &&
    (consent ? (
      <div
        id="cookieConsentBtn"
        style={{
          position: "fixed",
          zIndex: 9999,
          bottom: 5,
          cursor: "pointer",
          right: 5,
        }}
        onClick={() => setConsent(false)}
      >
        <img
          width={35}
          src="https://cdn-icons-png.flaticon.com/128/13654/13654083.png"
        />
      </div>
    ) : (
      <>
        <Modal
          show={showModal}
          size="lg"
          onHide={() => setShowModal(false)}
          scrollable
        >
          <Modal.Header>
            <h4>Informasjonskapsler</h4>
          </Modal.Header>
          <Modal.Body>
            <i>Sist oppdatert 22.10.2024</i> <hr />
            <ol>
              <li>
                <b>Hvilke opplysninger samler vi inn?</b> <br />
                <p>
                  Vi samler inn informasjon som er nødvendig for å levere våre
                  tjenester og oppfylle våre forpliktelser overfor deg. Dette
                  kan inkludere navn, kontaktinformasjon, e-postadresse og andre
                  relevante opplysninger. Vi samler også inn tekniske
                  opplysninger som IP-adresse og informasjon om din bruk av
                  nettsiden, inkludert bruk av informasjonskapsler.
                </p>
                <b>Logging av brukeraktiviteter</b>
                <p>
                  Når du besøker vår nettside og logger deg inn, samler vi inn
                  informasjon om din aktivitet på nettstedet. Dette inkluderer:
                  <ul>
                    <li>Hvilke sider du besøker, og når</li>
                    <li>
                      Endringer du gjør på dine brukerinstillinger eller annen
                      informasjon (prosjekter m.m.)
                    </li>
                    <li>Tidspunkt for innlogging og andre handlinger</li>
                  </ul>
                </p>
                <b>Formål med denne innsamlingen er å:</b>
                <p>
                  <ul>
                    <li>Ivareta sikkerheten på nettsiden</li>
                    <li>Forbedre brukeropplevelsen og tilpasse tjenestene</li>
                    <li>
                      Feilsøking og forebygging av misbruk av våre tjenester
                    </li>
                  </ul>
                </p>
                <b>Lagring og deling av logger</b>
                <p>
                  Vi lagrer aktivitetslogger så lenge det er nødvendig for å
                  oppfylle formålene nevnt ovenfor. Logger kan også anonymiseres
                  eller slettes etter behov. Data fra logger deles ikke med
                  tredjepartsleverandører.
                </p>
                <p>
                  Du kan be om innsyn i våre data som omhandler deg ved å ta
                  kontakt på vannpost@tromso.kommune.no
                </p>
              </li>
              <li>
                <b>Formål med behandling av personopplysninger</b> <br />
                <p>Personopplysningene brukes til følgende formål:</p>
                <ol>
                  <li>
                    Administrere brukertilganger og rettigheter i prosjektene
                    våre
                  </li>
                  <li>Kommunikasjon med deg i forbindelse med tjenester</li>
                  <li>Sikre kvalitet og sikkerhet på våre tjenester</li>
                  <li>Overholde juridiske forpliktelser</li>
                </ol>
                <br />
              </li>
              <li>
                <b>Lovlig grunnlag for behandling</b> <br />
                <p>
                  Behandlingen av dine personopplysninger skjer med grunnlag i
                  ett av følgende:
                </p>
                <ol>
                  <li>Samtykke fra deg, for eksempel ved registrering</li>
                  <li>Oppfyllelse av en avtale, som bruk av tjenestene</li>
                  <li>Juridiske forpliktelser vi må følge</li>
                  <li>
                    Legitime interesser, som å ivareta sikkerheten til vår
                    nettside og forbedre våre tjenester
                  </li>
                </ol>
                <br />
              </li>
              <li>
                <b>Lagring av opplysninger</b> <br />
                <p>
                  Vi lagrer dine personopplysninger så lenge det er nødvendig
                  for formålene de ble samlet inn for. Dette kan variere
                  avhengig av typen opplysning og lovkravene som gjelder.
                </p>
              </li>
              <li>
                <b>Hvordan bruker vi opplysningene?</b> <br />
                <p>
                  Vi bruker dine opplysninger til å håndtere brukertilganger og
                  rettigheter i de ulike prosjektene.
                </p>
              </li>
              <li>
                <b>Hvordan beskytter vi dine opplysninger?</b> <br />
                <p>
                  Vi tar rimelige forholdsregler for å beskytte dine
                  personopplysninger mot uautorisert tilgang, endring, avsløring
                  eller ødeleggelse. Dette inkluderer tekniske og
                  organisatoriske tiltak for å sikre dataene dine.
                </p>
              </li>
              <li>
                <b>Dine rettigheter</b> <br />
                <p>Du har rett til å:</p>
                <ol>
                  <li>
                    Be om innsyn i, retting eller sletting av dine
                    personopplysninger
                  </li>
                  <li>
                    Begrense behandlingen av dine data eller motsette deg visse
                    former for behandling
                  </li>
                  <li>
                    Trekke tilbake ditt samtykke når som helst, der samtykke er
                    grunnlaget for behandlingen
                  </li>
                  <li>
                    Klage til Datatilsynet dersom du mener vi ikke har oppfylt
                    dine rettigheter
                  </li>
                </ol>
                <span>
                  For å utøve dine rettigheter kan du kontakte oss på
                  vannpost@tromso.kommune.no.
                </span>{" "}
                <br />
                <br />
              </li>
              <li>
                <b>Informasjonskapsler (cookies) vi bruker</b> <br />
                <p>
                  Vi bruker informasjonskapsler for å forbedre brukeropplevelsen
                  på nettsiden. Dette inkluderer nødvendige informasjonskapsler
                  som <b>PHPSESSID</b> (som identifiserer økten din),{" "}
                  <b>theme</b> (som lagrer preferanser for utseende), og{" "}
                  <b>cookieConsent</b> (som lagrer om du har godtatt bruken av
                  informasjonskapsler). Du kan administrere og slette
                  informasjonskapsler i din nettleser.
                </p>
              </li>
              <li>
                <b>Endringer i personvernerklæringen</b> <br />
                <p>
                  Vi kan oppdatere denne personvernerklæringen fra tid til annen
                  for å gjenspeile endringer i våre tjenester. Eventuelle
                  endringer vil bli varslet.
                </p>
              </li>
              <li>
                <b>Kontakt oss</b> <br />
                <p>Ta kontakt med oss på vannpost@tromso.kommune.no</p>
              </li>
            </ol>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowModal(false)}>
              Lukk
            </Button>
          </Modal.Footer>
        </Modal>
        <div
          id="cookieConsent"
          style={{
            position: "fixed",
            zIndex: 9999,
            bottom: 50,
            width: "90dvw",
            left: 0,
            right: 0,
            marginLeft: "auto",
            marginRight: "auto",
            backgroundColor: "darkblue",
            display: showModal ? "none" : "block",
          }}
        >
          <div className="d-flex justify-content-between align-items-center flex-wrap">
            <div
              className="p-4 m-2 d-flex"
              style={{
                minWidth: "75%",
              }}
            >
              <div className="p-2 me-4">
                <img
                  width={55}
                  src="https://cdn-icons-gif.flaticon.com/6569/6569128.gif"
                />
              </div>
              <div>
                <span style={{ color: "white", fontSize: 22 }}>
                  Denne nettsiden benytter informasjonskapsler (cookies) for å
                  gi deg den beste opplevelsen av vår nettside.
                </span>{" "}
                <br />
                <span
                  onClick={() => setShowModal(true)}
                  style={{
                    color: "white",
                    textDecoration: "underline",
                    cursor: "pointer",
                    fontSize: 20,
                  }}
                >
                  Klikk her for å lese mer.
                </span>
              </div>
            </div>
            <div className="d-flex flex-column flex-grow-1 p-4 justify-content-between">
              <Button
                className="m-2 w-100"
                variant="success"
                onClick={() => setCookieConsent(true)}
              >
                Godkjenn cookies
              </Button>
              <Button
                className="m-2 w-100"
                variant="danger"
                onClick={() => setCookieConsent(false)}
              >
                Avslå cookies
              </Button>
            </div>
          </div>
        </div>
      </>
    ))
  );
}
