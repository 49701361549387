import React, { useState } from "react";
import { Table, Button } from "react-bootstrap";

export default function VA_Kapitalkalkulator() {
  const [investering, setInvestering] = useState(10000000);
  const [avskriving, setAvskriving] = useState(40);
  const [rente, setRente] = useState(3.5);
  const [vannPris, setVannPris] = useState(28.04);
  const [avløpPris, setAvløpPris] = useState(33.24);
  const [prisØkn, setPrisØkn] = useState(3.5);
  const [type, setType] = useState("Vann");
  const [nyeKunder, setNyeKunder] = useState(10);
  const [breakEvenCustomers, setBreakEvenCustomers] = useState(null);
  const [yearOfPositiveCashFlow, setYearOfPositiveCashFlow] = useState(null);
  const [yearOfProjectPayback, setYearOfProjectPayback] = useState(null);

  // Helper function for formatting numbers
  const convertNumber = (number) => {
    let num = parseInt(number);
    if (isNaN(num)) {
      return number;
    }
    return num.toLocaleString();
  };

  const getUB = (aar) => {
    return investering - (investering / avskriving) * aar;
  };

  const beregnRente = (aar) => {
    const oldUB = getUB(aar - 1);
    const currentUB = getUB(aar);
    const gjennomsnitt = (oldUB + currentUB) / 2;
    const rent = rente / 100;
    return parseInt(gjennomsnitt * rent);
  };

  const getInntekt = (aar, kunder = nyeKunder) => {
    let pris = type === "Vann" ? vannPris : avløpPris;
    if (aar > 0) {
      const newPris = pris * Math.pow(1 + prisØkn / 100, aar) * 120;
      return newPris * kunder;
    }
    return 0;
  };

  const getTotalCosts = (aar) => {
    const depreciation = parseInt(investering / avskriving);
    const interestCost = beregnRente(aar);
    return depreciation + interestCost;
  };

  // Funksjon for å beregne totale kostnader over hele avskrivningsperioden
  const getTotalCostsOverPeriod = () => {
    return Array.from({ length: avskriving }).reduce(
      (acc, _, index) => acc + getTotalCosts(index + 1),
      0
    );
  };

  // Funksjon for å beregne totale inntekter over hele avskrivningsperioden
  const getTotalIncomeOverPeriod = () => {
    return Array.from({ length: avskriving }).reduce(
      (acc, _, index) => acc + getInntekt(index + 1),
      0
    );
  };

  // Funksjon for å finne antall kunder for å nå break-even
  const getBreakEvenCustomers = () => {
    let customers = 1; // Start med minst 1 kunde
    let totalIncome = 0;
    const totalCost = getTotalCostsOverPeriod();

    while (totalIncome < totalCost) {
      totalIncome = Array.from({ length: avskriving }).reduce(
        (acc, _, index) => acc + getInntekt(index + 1, customers),
        0
      );

      if (totalIncome < totalCost) {
        customers++;
      }
    }

    setBreakEvenCustomers(customers);
  };

  const setBreakEvenKunder = () => {
    let customers = 1; // Start med minst 1 kunde
    let totalIncome = 0;
    const totalCost = getTotalCostsOverPeriod();

    while (totalIncome < totalCost) {
      totalIncome = Array.from({ length: avskriving }).reduce(
        (acc, _, index) => acc + getInntekt(index + 1, customers),
        0
      );

      if (totalIncome < totalCost) {
        customers++;
      }
    }

    setNyeKunder(customers);
  };

  // 1) Funksjon for å finne året når kontantstrømmen for første gang blir positiv (inntekter > kostnader)
  const getYearOfPositiveCashFlow = () => {
    for (let year = 1; year <= avskriving; year++) {
      const income = getInntekt(year);
      const cost = getTotalCosts(year);
      const cashFlow = income - cost;

      if (cashFlow > 0) {
        return year; // Returner året når kontantstrømmen blir positiv
      }
    }
    return null; // Returner null hvis ingen år har positiv kontantstrøm innen avskrivningsperioden
  };

  // 2) Funksjon for å finne året når prosjektet totalt sett er nedbetalt (kumulativ kontantstrøm >= 0)
  const getYearOfProjectPayback = () => {
    let cumulativeCashFlow = -investering; // Start med negativ kontantstrøm lik investeringen
    let year = 1;

    // Løp gjennom år etter år til prosjektet er nedbetalt
    while (true) {
      const income = getInntekt(year);
      const cost = getTotalCosts(year);
      cumulativeCashFlow += income - cost; // Legg til årlig kontantstrøm i kumulativ sum

      if (cumulativeCashFlow >= 0) {
        return year; // Returner året når prosjektet er nedbetalt
      }

      year++;
      if (year > 1000) {
        return null; // Avslutt etter 1000 år for å unngå uendelig loop
      }
    }
  };

  const handleCalculateAnalysis = () => {
    const positiveCashFlowYear = getYearOfPositiveCashFlow();
    const projectPaybackYear = getYearOfProjectPayback();

    setYearOfPositiveCashFlow(positiveCashFlowYear);
    setYearOfProjectPayback(projectPaybackYear);
  };

  return (
    <>
      {/* Input Fields */}
      <div
        className="d-flex flex-column"
        style={{ position: "sticky", left: 0, zIndex: 1 }}
      >
        {/* Investment, Depreciation, and Interest Input Fields */}
        <div className="d-flex mb-2">
          <div style={{ minWidth: "300px" }}>
            <b>Investeringsnivå</b>
          </div>
          <input
            type="number"
            defaultValue={investering}
            onChange={(e) => setInvestering(parseInt(e.target.value))}
          />
        </div>
        <div className="d-flex mb-2">
          <div style={{ minWidth: "300px" }}>
            <b>Avskrivingstid (år)</b>
          </div>
          <input
            type="number"
            defaultValue={avskriving}
            onChange={(e) => setAvskriving(parseInt(e.target.value))}
          />
        </div>
        <div className="d-flex">
          <div style={{ minWidth: "300px" }}>
            <b>Gjennomsnittlig rente (år)</b>
          </div>
          <input
            type="number"
            defaultValue={rente}
            onChange={(e) => setRente(parseFloat(e.target.value))}
          />
          <span>&nbsp; %</span>
        </div>
        <hr />
        {/* New Customers and Price Increase Input Fields */}
        <div className="d-flex mb-2">
          <div style={{ minWidth: "300px" }}>
            <b>Nye kunder</b>
          </div>
          <input
            type="number"
            //defaultValue={nyeKunder}
            value={nyeKunder}
            onChange={(e) => setNyeKunder(parseInt(e.target.value))}
          />
          <Button
            size="sm"
            className="ms-1"
            onClick={() => setBreakEvenKunder()}
          >
            Beregn break-even
          </Button>
        </div>
        <div className="d-flex mb-2">
          <div style={{ minWidth: "300px" }}>
            <b>Årlig prisøkning</b>
          </div>
          <input
            type="number"
            defaultValue={prisØkn}
            onChange={(e) => setPrisØkn(parseFloat(e.target.value))}
          />
          <span>&nbsp; %</span>
        </div>
        <div className="d-flex">
          <div style={{ minWidth: "300px" }}>
            <b>Valgt type</b>
          </div>
          <select
            className="form-select"
            onChange={(e) => setType(e.target.value)}
            style={{ width: "205px" }}
          >
            <option selected value="Vann">
              Vann
            </option>
            <option value="Avløp">Avløp</option>
          </select>
        </div>
      </div>

      <hr />

      {/* Investment Cost Table */}
      <h4>Investeringskostnad</h4>
      <Table bordered>
        <thead>
          <tr>
            <th
              style={{ minWidth: 220, position: "sticky", left: 79, zIndex: 1 }}
            >
              Detaljer
            </th>
            {Array.from({ length: avskriving + 1 }, (_, index) => (
              <th className="text-center" style={{ minWidth: 150 }} key={index}>
                År {index}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          <tr>
            <td
              style={{
                position: "sticky",
                left: 79,
                zIndex: 1,
              }}
            >
              Avskrivinger
            </td>
            <td className="text-center">0</td>
            {Array.from({ length: avskriving }, (_, index) => (
              <td key={index} className="text-center">
                {convertNumber(parseInt(investering / avskriving))}
              </td>
            ))}
          </tr>
          <tr>
            <td
              style={{
                position: "sticky",
                left: 79,
                zIndex: 1,
              }}
            >
              Rentekostnad
            </td>
            <td className="text-center">
              {convertNumber(parseInt((investering / 2) * (rente / 100)))}
            </td>
            {Array.from({ length: avskriving }, (_, index) => (
              <td className="text-center" key={index}>
                {convertNumber(beregnRente(index + 1))}
              </td>
            ))}
          </tr>
          <tr className="fw-bold">
            <td
              style={{
                position: "sticky",
                left: 79,
                zIndex: 1,
              }}
            >
              Sum
            </td>
            <td className="text-center">
              {convertNumber((investering / 2) * (rente / 100))}
            </td>
            {Array.from({ length: avskriving }, (_, index) => (
              <td className="text-center" key={index}>
                {convertNumber(
                  beregnRente(index + 1) + parseInt(investering / avskriving)
                )}
              </td>
            ))}
          </tr>
        </tbody>
      </Table>

      <hr />

      {/* Income Table */}
      <h4>Gebyrinntekter - nye kunder</h4>
      <Table bordered>
        <thead>
          <tr>
            <th
              style={{ minWidth: 220, position: "sticky", left: 79, zIndex: 1 }}
            >
              Detaljer
            </th>
            {Array.from({ length: avskriving + 1 }, (_, index) => (
              <th className="text-center" style={{ minWidth: 150 }} key={index}>
                År {index}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          <tr>
            <td
              style={{
                position: "sticky",
                left: 79,
                zIndex: 1,
              }}
            >
              Gebyrinntekter
            </td>
            <td className="text-center">
              {convertNumber(
                type === "Vann"
                  ? vannPris * nyeKunder * 120
                  : avløpPris * nyeKunder * 120
              )}
            </td>
            {Array.from({ length: avskriving }, (_, index) => (
              <td className="text-center" key={index}>
                {convertNumber(getInntekt(index + 1))}
              </td>
            ))}
          </tr>
        </tbody>
      </Table>

      <hr />

      {/* Analysis Table */}
      <h4>Analyse</h4>
      <Table bordered>
        <thead>
          <tr>
            <th
              style={{ minWidth: 220, position: "sticky", left: 79, zIndex: 1 }}
            >
              Detaljer
            </th>
            {Array.from({ length: avskriving + 1 }, (_, index) => (
              <th className="text-center" style={{ minWidth: 150 }} key={index}>
                År {index}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          <tr>
            <td
              style={{
                position: "sticky",
                left: 79,
                zIndex: 1,
              }}
            >
              Inntekter
            </td>
            <td className="text-center">
              {convertNumber(
                type === "Vann"
                  ? vannPris * nyeKunder * 120
                  : avløpPris * nyeKunder * 120
              )}
            </td>
            {Array.from({ length: avskriving }, (_, index) => (
              <td className="text-center" key={index}>
                {convertNumber(getInntekt(index + 1))}
              </td>
            ))}
          </tr>
          <tr>
            <td
              style={{
                position: "sticky",
                left: 79,
                zIndex: 1,
              }}
            >
              Kostnader
            </td>
            <td className="text-center">
              {convertNumber(parseInt((investering / 2) * (rente / 100)))}
            </td>
            {Array.from({ length: avskriving }, (_, index) => (
              <td className="text-center" key={index}>
                {convertNumber(
                  parseInt(investering / avskriving) + beregnRente(index + 1)
                )}
              </td>
            ))}
          </tr>
          <tr className="fw-bold">
            <td
              style={{
                position: "sticky",
                left: 79,
                zIndex: 1,
              }}
            >
              Sum
            </td>
            {/* Beregner sum for år 0 */}
            <td className="text-center fw-bold">
              {convertNumber(
                (type === "Vann"
                  ? vannPris * nyeKunder * 120
                  : avløpPris * nyeKunder * 120) -
                  parseInt((investering / 2) * (rente / 100))
              )}
            </td>
            {Array.from({ length: avskriving }, (_, index) => (
              <td className="text-center fw-bold" key={index}>
                {convertNumber(
                  getInntekt(index + 1) - getTotalCosts(index + 1)
                )}
              </td>
            ))}
          </tr>
        </tbody>
      </Table>

      {/* Totals for Analysis */}
      <h4>Analyse</h4>
      <p>
        <strong>Totale kostnader over hele perioden:</strong>{" "}
        {convertNumber(getTotalCostsOverPeriod())}
      </p>
      <p>
        <strong>Totale inntekter over hele perioden:</strong>{" "}
        {convertNumber(getTotalIncomeOverPeriod())}
      </p>
      <p>
        <strong>Resultat over hele perioden:</strong>{" "}
        {convertNumber(getTotalIncomeOverPeriod() - getTotalCostsOverPeriod())}
      </p>

      {/* Break-Even Calculation Button */}
      <Button onClick={getBreakEvenCustomers}>Beregn Break-Even Kunder</Button>
      {breakEvenCustomers !== null && (
        <p>Antall nye kunder nødvendig for break-even: {breakEvenCustomers}</p>
      )}

      {/* Knapp for å utføre analysene */}
      <Button onClick={handleCalculateAnalysis}>Beregn Lønnsomhetsår</Button>

      {/* Vise resultatene */}
      {yearOfPositiveCashFlow !== null ? (
        <p>Første år med positiv kontantstrøm: {yearOfPositiveCashFlow}</p>
      ) : (
        <p>Ingen positiv kontantstrøm innen avskrivningsperioden.</p>
      )}

      {yearOfProjectPayback !== null ? (
        <p>År når prosjektet er nedbetalt: {yearOfProjectPayback}</p>
      ) : (
        <p>Prosjektet blir ikke nedbetalt innen 1000 år.</p>
      )}
    </>
  );
}
