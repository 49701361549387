import React, { useState, useEffect, useMemo } from "react";
import { Button, Spinner, Table } from "react-bootstrap";

import ProjectHistory from "../projects/history";

const convertNumber = (number) => {
  let num = parseInt(number);
  if (isNaN(num)) {
    return number;
  }
  return num.toLocaleString();
};

const Budsjettregulering = ({ type }) => {
  const [typeData, setTypeData] = useState([]);
  const [data, setData] = useState([]);

  // Options
  const [fromYear, setFromYear] = useState(null);
  const [fromVersion, setFromVersion] = useState(null);
  const [fromName, setFromName] = useState(null);
  const [toYear, setToYear] = useState(null);
  const [toVersion, setToVersion] = useState(null);
  const [toName, setToName] = useState(null);

  // Chose year to show
  const [budgetYear, setBudgetYear] = useState(0);

  // Expanded Proj
  const [expandedProj, setExpandedProj] = useState(null);

  // Hoved eller underprosjetker
  const [onlyMainProjects, setOnlyMainProjects] = useState(false);

  useEffect(() => {
    const getTypeData = async () => {
      try {
        const res = await fetch(
          "https://prosjekt.tkva.no/api/history.php?avviksanalyse&getType=" +
            type,
          { credentials: "include" }
        );
        const data = await res.json();
        setTypeData(data);
      } catch (err) {
        return;
      }
    };
    getTypeData();
  }, []);

  const runReport = async (mainOnly = false) => {
    try {
      const res = await fetch(
        "https://prosjekt.tkva.no/api/history.php?budsjettregulering&runType=" +
          type +
          "&fromYear=" +
          fromYear +
          "&fromVersion=" +
          fromVersion +
          "&toYear=" +
          toYear +
          "&toVersion=" +
          toVersion +
          "&mainOnly=" +
          mainOnly,
        { credentials: "include" }
      );
      const data = await res.json();
      setData(data);
    } catch (err) {
      alert("Noe gikk galt...");
    }
  };

  const getTotal = (n) => {
    let total = 0;
    data.forEach((d) => {
      if (n === 1) {
        total += parseInt(d.e1);
      }
      if (n === 2) {
        total += parseInt(d.e2);
      }
    });

    return total;
  };

  const exportData = async () => {
    const postData = {
      action: "budgetExport",
      data: data,
    };
    try {
      const res = await fetch("https://prosjekt.tkva.no/api/history.php", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(postData),
        credentials: "include",
      });

      if (!res.ok) {
        alert("Noe gikk galt.. dessverre");
        return;
      }

      const responseData = await res.json();
      const filename = responseData.filename;

      // Last ned filen
      const fileRes = await fetch(
        `https://prosjekt.tkva.no/_files/${filename}`,
        {
          method: "GET",
          credentials: "include",
        }
      );

      if (!fileRes.ok) {
        alert("Kunne ikke laste ned filen.");
        return;
      }

      const blob = await fileRes.blob();
      const url = window.URL.createObjectURL(blob);

      // Opprett lenken og klikk på den
      const a = document.createElement("a");
      a.href = url;
      a.download = filename;
      document.body.appendChild(a);
      a.click();

      // Rydd opp
      a.remove();
      window.URL.revokeObjectURL(url);
    } catch (err) {
      alert("Noe gikk galt..");
      console.error(err);
    }
  };

  return (
    <>
      {typeData.length <= 0 && (
        <div className="d-flex align-items-center justify-content-center">
          <div>
            <Spinner />
          </div>
        </div>
      )}

      {typeData.length > 0 && data.length <= 0 && (
        <>
          <div style={{ maxWidth: "1200px" }}>
            <div className="d-flex justify-content-center">
              <div className="p-2" style={{ minWidth: "600px" }}>
                <h6>1. Velg kolonne</h6>
                <div className="content">
                  <select
                    className="form-select"
                    onChange={(e) => {
                      const selectedOption =
                        e.target.options[e.target.selectedIndex];
                      const year = selectedOption.getAttribute("data-year");
                      const version =
                        selectedOption.getAttribute("data-version");
                      const name = selectedOption.getAttribute("data-name");
                      setFromYear(year);
                      setFromVersion(version);
                      setFromName(name);
                    }}
                  >
                    <option selected disabled>
                      Velg kolonne
                    </option>
                    {typeData?.map((type, typeKey) => {
                      return (
                        <option
                          data-year={type.year}
                          data-version={type.version}
                          data-name={type.comment}
                          key={typeKey}
                        >
                          {type.year} - {type.comment}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
              <div className="p-2" style={{ minWidth: "600px" }}>
                <h6>2. Velg kolonne</h6>
                <div className="content">
                  <select
                    className="form-select"
                    onChange={(e) => {
                      const selectedOption =
                        e.target.options[e.target.selectedIndex];
                      const year = selectedOption.getAttribute("data-year");
                      const version =
                        selectedOption.getAttribute("data-version");
                      const name = selectedOption.getAttribute("data-name");
                      setToYear(year);
                      setToVersion(version);
                      setToName(name);
                    }}
                  >
                    <option selected disabled>
                      Velg kolonne
                    </option>
                    {typeData?.map((type, typeKey) => {
                      return (
                        <option
                          data-year={type.year}
                          data-version={type.version}
                          data-name={type.comment}
                          key={typeKey}
                        >
                          {type.year} - {type.comment}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-end h-100">
              <div className="d-flex align-items-end">
                <Button onClick={() => runReport()}>Kjør rapport</Button>
              </div>
            </div>
          </div>
        </>
      )}

      {data?.length > 0 && (
        <>
          <div
            className="d-flex justify-content-end"
            style={{ maxWidth: "1200px" }}
          >
            <Button
              size="sm"
              variant="success"
              style={{ marginBottom: 5, marginRight: 5 }}
              onClick={() => exportData()}
            >
              Eksporter til Excel
            </Button>
            <Button
              size="sm"
              onClick={() => {
                setOnlyMainProjects((prev) => {
                  runReport(!prev);
                  return !prev;
                });
              }}
              variant="primary"
              style={{ marginBottom: 5, marginRight: 5 }}
            >
              {onlyMainProjects
                ? "Vis alle prosjekter"
                : "Vis kun hovedprosjekter"}
            </Button>
            <Button
              size="sm"
              onClick={() => setData([])}
              variant="warning"
              style={{ marginBottom: 5 }}
            >
              Nullstill
            </Button>
          </div>
          <div style={{ maxWidth: "1200px" }}>
            <Table className="sticky-thc">
              <thead>
                <tr style={{ position: "sticky", top: 0, zIndex: 2 }}>
                  <th>Prosjekt</th>
                  <th>Konto</th>
                  <th>Tjeneste</th>
                  <th>Pnr</th>
                  <th>Anlegg</th>
                  <th>Regulering</th>
                  <th style={{ textAlign: "right" }}>{fromName}</th>
                  <th style={{ textAlign: "right" }}>{toName}</th>
                  <th>#</th>
                </tr>
              </thead>
              <tbody>
                {data?.map((d, k) => {
                  return (
                    <tr key={k}>
                      <td>{d.pname}</td>
                      <td>{d.konto}</td>
                      <td>{d.tjeneste}</td>
                      <td>{d.pnr}</td>
                      <td>&nbsp;</td>
                      <td>{convertNumber(d.avvik)}</td>
                      <td
                        style={{
                          backgroundColor: "transparent",
                          textAlign: "right",
                        }}
                      >
                        {convertNumber(d.e1)}
                      </td>

                      <td
                        style={{
                          backgroundColor: "transparent",
                          textAlign: "right",
                        }}
                      >
                        {convertNumber(d.e2)}
                      </td>

                      <td style={{ backgroundColor: "transparent" }}>
                        <ProjectHistory project={d.pnr}>
                          <i className="bi bi-chat" />
                        </ProjectHistory>
                      </td>
                    </tr>
                  );
                })}
                <tr>
                  <th>&nbsp;</th>
                  <th>&nbsp;</th>
                  <th>&nbsp;</th>
                  <th>&nbsp;</th>
                  <th>&nbsp;</th>
                  <th>{convertNumber(getTotal(2) - getTotal(1))}</th>
                  <th style={{ textAlign: "right" }}>
                    {convertNumber(getTotal(1))}
                  </th>
                  <th style={{ textAlign: "right" }}>
                    {convertNumber(getTotal(2))}
                  </th>
                  <th>&nbsp;</th>
                </tr>
              </tbody>
            </Table>
          </div>
        </>
      )}
    </>
  );
};

export default Budsjettregulering;
