import React, { useEffect, useState, useMemo, useRef } from "react";
import { Alert, Button, Dropdown, Spinner, Table } from "react-bootstrap";
import ProjectHistory from "./history";

import { useContent } from "../../hooks/projects";
import LogUser from "../../hooks/log";
import { useParams } from "react-router-dom";

const convertNumber = (number) => {
  let num = parseInt(number);
  if (isNaN(num)) {
    return number;
  }
  return num.toLocaleString();
};
const formatName = (fullName) => {
  try {
    const nameParts = fullName.split(" "); // Split navnet ved mellomrom
    if (nameParts.length <= 1) return fullName; // Hvis det bare er ett navn, returner det som det er

    const firstName = nameParts[0]; // Første del av navnet
    const initials = nameParts
      .slice(1)
      .map((name) => `${name.charAt(0)}.`)
      .join(" "); // Resten av delene som initialer

    return `${firstName} ${initials}`; // Kombiner fornavn og initialer
  } catch (err) {
    return fullName;
  }
};

export default function Projects() {
  const {
    projects,
    getProjectData,
    projectsDate,
    activeSub,
    setActiveSub,
    activeSubSub,
    setActiveSubSub,
    projectsTableScrollTop,
    setProjectsTableScrollTop,
    pinList,
    setPinList,
    keyword,
    setKeyword,
    mainProjFilter,
    setMainProjFilter,
    plProjFilter,
    setPlProjFilter,
    mainProjects,
    PLinProjects,
    loaded,
    error,
    clearFilters,
    isBrutto,
    toggleTableMode,
  } = useContent();

  // Params
  const params = useParams();

  // Update info and scrollinfo
  const updated = projectsDate; // Last update projectsdata
  const scrollTimeoutRef = useRef(null); // ScrollTimer

  // Data to table
  const data = useMemo(() => {
    return projects;
  }, [projects]);

  // Hyperlink
  useEffect(() => {
    if (params.pnr) {
      setKeyword(params.pnr);
    }
  }, [params]);

  // Pinned
  const pinHeight = 135;

  // FIlter
  const [bigTable, setBigTable] = useState(false);

  // Search Project
  const projectSearchRef = useRef(null);

  // To top visible
  const [backToTopVisible, setBackToTopVisible] = useState(false);

  const scrollTable = () => {
    window.scrollTo({ top: projectsTableScrollTop, behavior: "smooth" });
  };

  const setScrollTable = (e) => {
    const scrollTop = e.target.documentElement.scrollTop;

    if (scrollTop > 50) {
      setBackToTopVisible(true);
    } else {
      setBackToTopVisible(false);
    }

    // Kansellerer den eksisterende timeouten hvis brukeren scroller igjen
    if (scrollTimeoutRef.current) {
      clearTimeout(scrollTimeoutRef.current);
    }

    // Starter en ny timeout for å lagre scrollTop etter 2,5 sekunders inaktivitet
    scrollTimeoutRef.current = setTimeout(() => {
      setProjectsTableScrollTop(scrollTop);
    }, 750);
  };

  useEffect(() => {
    window.addEventListener("scroll", setScrollTable);
    LogUser("Prosjekter", "Brukeren åpnet prosjekter");
    return () => window.removeEventListener("scroll", setScrollTable);
  }, []);

  useEffect(() => {
    if (loaded) {
      scrollTable();
    }
  }, [loaded]);

  const sortedPinList = useMemo(() => {
    const comparePinItems = (a, b) => {
      // Sammenlign parentIds først
      if (a.parentId !== b.parentId) {
        return a.parentId - b.parentId;
      }

      // Hvis parentIds er like, sammenlign subIds
      if (a.subId !== b.subId) {
        return a.subId - b.subId;
      }

      // Hvis subIds også er like, sammenlign subsubIds
      if (a.subsubId !== b.subsubId) {
        return a.subsubId - b.subsubId;
      }

      return 0;
    };

    return [...pinList].sort(comparePinItems);
  }, [pinList]);

  return (
    <>
      {loaded ? (
        <>
          {error ? (
            <Alert variant="danger">
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  <b>Noe gikk galt.</b> Prøv igjen senere <br />{" "}
                  <span className="err-msg text-muted">Error: {error}</span>
                </div>
                <div className="d-flex flex-column justify-content-center align-items-center">
                  <i
                    className="bi bi-arrow-clockwise"
                    style={{ cursor: "pointer" }}
                    onClick={() => getProjectData()}
                  />
                  <span
                    style={{ cursor: "pointer" }}
                    onClick={() => getProjectData()}
                  >
                    Last på nytt
                  </span>
                </div>
              </div>
            </Alert>
          ) : (
            <>
              {/*
               ** Hvis vi skal tillatte ulike årstall må man se på en loop mellom {data[0].year} og {proj.year}
               */}

              <div className="row reverse">
                <div className="d-flex flex-wrap justify-content-between">
                  <span className="me-4 mt-2">Oppdatert {updated}</span>
                  <div className="flex-grow-1">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Søk etter prosjekt"
                      aria-label="Søk etter prosjekt"
                      aria-describedby="basic-addon1"
                      style={{ width: "100%", margin: 0 }}
                      ref={projectSearchRef}
                      autoFocus
                      defaultValue={keyword}
                      autoCorrect="false"
                      onChange={(e) => {
                        setKeyword(e.target.value);
                      }}
                      onFocus={(e) => e.target.select()}
                    />
                  </div>
                  <div className="d-flex flex-row-reverse mb-2 ms-4 mt-0">
                    <Button
                      size="sm"
                      variant="light"
                      style={{ marginRight: 5 }}
                      onClick={() => setBigTable((prev) => !prev)}
                    >
                      {bigTable ? "Liten tabell" : "Stor tabell"}
                    </Button>
                    <Button
                      onClick={() => getProjectData(isBrutto)}
                      size="sm"
                      variant="light"
                      style={{ marginRight: 5 }}
                    >
                      <i className="bi bi-arrow-clockwise" />
                    </Button>
                  </div>
                </div>
              </div>

              <div
                id="projectTable"
                style={{
                  cursor: "default",
                  position: "relative",
                }}
                //onLoad={() => scrollTable()}
                //onScroll={(e) => setScrollTable(e.nativeEvent.target.scrollTop)}
                //onScroll={setScrollTable}
              >
                <Table hover className="sticky-thc">
                  <thead>
                    <tr>
                      <th
                        colSpan={2}
                        style={{ textAlign: "left", width: "25%" }}
                      >
                        Prosjekt
                      </th>
                      <th>PL</th>
                      {bigTable
                        ? Array.from({ length: 10 }, (_, index) => (
                            <th key={index}>
                              {parseInt(data[0]?.year) + index}
                            </th>
                          ))
                        : Array.from({ length: 4 }, (_, index) => (
                            <th key={index}>
                              {parseInt(data[0]?.year) + index}
                            </th>
                          ))}
                      <th>#</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data?.map((proj, index) => {
                      return (
                        <React.Fragment key={index}>
                          <tr
                            className={
                              pinList.find((item) => item.pnr === proj.name)
                                ? `mainProject pinned`
                                : `mainProject`
                            }
                            style={
                              pinList.find((item) => item.pnr === proj.name)
                                ? {
                                    cursor: "pointer",
                                    height: "110px",
                                    top:
                                      pinHeight +
                                      parseInt(
                                        parseInt(
                                          sortedPinList.findIndex(
                                            (item) => item.pnr === proj.name
                                          )
                                        ) * 105
                                      ) +
                                      "px",
                                  }
                                : { cursor: "pointer", height: "110px" }
                            }
                            onClick={() => {
                              setActiveSub((prev) => {
                                if (prev.includes(proj.name)) {
                                  // Fjern prosjektet hvis det allerede er i listen
                                  return prev.filter(
                                    (name) => name !== proj.name
                                  );
                                } else {
                                  // Legg til prosjektet hvis det ikke er i listen
                                  return [...prev, proj.name];
                                }
                              });
                            }}
                          >
                            <td colSpan={2}>
                              <img
                                alt=""
                                src={proj.img}
                                height={25}
                                width={25}
                              />{" "}
                              {proj.name}
                              <br />
                              <small className="text-muted">
                                {proj.version}. Versjon: {proj.versionDesc}
                              </small>{" "}
                              <br />
                              <small className="text-muted">
                                Årstall: {proj?.year}
                              </small>{" "}
                            </td>

                            <td>{formatName(proj.pl)}</td>

                            <td>{convertNumber(proj.e1)}</td>

                            <td>{convertNumber(proj.e2)}</td>

                            <td>{convertNumber(proj.e3)}</td>

                            <td>{convertNumber(proj.e4)}</td>
                            {bigTable && (
                              <>
                                <td>{convertNumber(proj.e5)}</td>

                                <td>{convertNumber(proj.e6)}</td>

                                <td>{convertNumber(proj.e7)}</td>

                                <td>{convertNumber(proj.e8)}</td>

                                <td>{convertNumber(proj.e9)}</td>

                                <td>{convertNumber(proj.e10)}</td>
                              </>
                            )}
                            <td
                              onClick={() =>
                                setActiveSub((prev) => {
                                  if (prev.includes(proj.name)) {
                                    // Fjern prosjektet hvis det allerede er i listen
                                    return prev.filter(
                                      (name) => name !== proj.name
                                    );
                                  } else {
                                    // Legg til prosjektet hvis det ikke er i listen
                                    return [...prev, proj.name];
                                  }
                                })
                              }
                            >
                              <>
                                {pinList.find(
                                  (item) => item.pnr === proj.name
                                ) ? (
                                  <i
                                    className="bi bi-pin-angle-fill"
                                    onClick={() =>
                                      setPinList((prev) =>
                                        prev.filter(
                                          (item) => item.pnr !== proj.name
                                        )
                                      )
                                    }
                                  />
                                ) : (
                                  <i
                                    className="bi bi-pin-angle"
                                    onClick={() =>
                                      setPinList((prev) => [
                                        ...prev,
                                        {
                                          pnr: proj.name,
                                          parentId: index,
                                          subId: null,
                                          subsubId: null,
                                        },
                                      ])
                                    }
                                  />
                                )}
                              </>
                            </td>
                          </tr>
                          {proj?.subData?.map((subProj, subIndex) => {
                            return (
                              <React.Fragment key={subIndex}>
                                <tr
                                  key={subIndex}
                                  className={`
                                    ${
                                      pinList.find(
                                        (item) => item.pnr === subProj.pnr
                                      )
                                        ? `subProject
                                        
                                        pinned pinCount${
                                          pinList.find(
                                            (item) => item.pnr === subProj.pnr
                                          )?.count
                                        } `
                                        : `subProject`
                                    } 
                                    `}
                                  style={
                                    activeSub.includes(proj.name)
                                      ? {
                                          display: "table-row",
                                          cursor: "pointer",
                                          height: "110px",
                                          top: pinList.find(
                                            (prev) => prev.pnr === subProj.pnr
                                          )
                                            ? pinHeight +
                                              parseInt(
                                                parseInt(
                                                  sortedPinList.findIndex(
                                                    (item) =>
                                                      item.pnr === subProj.pnr
                                                  )
                                                ) * 105
                                              ) +
                                              "px"
                                            : 0, // Sett toppen til 0 hvis elementet ikke er festet
                                        }
                                      : { display: "none", cursor: "pointer" }
                                  }
                                  onClick={() => {
                                    setActiveSubSub((prev) => {
                                      if (prev.includes(subProj.pnr)) {
                                        // Fjern prosjektet hvis det allerede er i listen
                                        return prev.filter(
                                          (name) => name !== subProj.pnr
                                        );
                                      } else {
                                        // Legg til prosjektet hvis det ikke er i listen
                                        return [...prev, subProj.pnr];
                                      }
                                    });
                                  }}
                                >
                                  <td colSpan={2}>
                                    <div
                                      className="d-flex flex-column"
                                      style={{ marginLeft: 25 }}
                                    >
                                      {subProj.pnr} - {subProj.pname}
                                      <span className="projType">
                                        <img
                                          alt=""
                                          src={proj.img}
                                          height={15}
                                          width={15}
                                        />{" "}
                                        {proj.name}
                                      </span>
                                    </div>
                                  </td>
                                  <td>{formatName(subProj.pl)}</td>
                                  <td>
                                    {convertNumber(subProj.e1)} <br />
                                    {parseInt(subProj.mainE1) !== 0 &&
                                      subProj.mainE1 !== subProj.e1 && (
                                        <span className="subAmount">
                                          ({convertNumber(subProj.mainE1)})
                                        </span>
                                      )}
                                  </td>
                                  <td>
                                    {convertNumber(subProj.e2)} <br />
                                    {parseInt(subProj.mainE2) !== 0 &&
                                      subProj.mainE2 !== subProj.e2 && (
                                        <span className="subAmount">
                                          ({convertNumber(subProj.mainE2)})
                                        </span>
                                      )}
                                  </td>
                                  <td>
                                    {convertNumber(subProj.e3)} <br />
                                    {parseInt(subProj.mainE3) !== 0 &&
                                      subProj.mainE3 !== subProj.e3 && (
                                        <span className="subAmount">
                                          ({convertNumber(subProj.mainE3)})
                                        </span>
                                      )}
                                  </td>
                                  <td>
                                    {convertNumber(subProj.e4)} <br />
                                    {parseInt(subProj.mainE4) !== 0 &&
                                      subProj.mainE4 !== subProj.e4 && (
                                        <span className="subAmount">
                                          ({convertNumber(subProj.mainE4)})
                                        </span>
                                      )}
                                  </td>
                                  {bigTable && (
                                    <>
                                      <td>
                                        {convertNumber(subProj.e5)} <br />
                                        {parseInt(subProj.mainE5) !== 0 &&
                                          subProj.mainE5 !== subProj.e5 && (
                                            <span className="subAmount">
                                              ({convertNumber(subProj.mainE5)})
                                            </span>
                                          )}
                                      </td>
                                      <td>
                                        {convertNumber(subProj.e6)} <br />
                                        {parseInt(subProj.mainE6) !== 0 &&
                                          subProj.mainE6 !== subProj.e6 && (
                                            <span className="subAmount">
                                              ({convertNumber(subProj.mainE6)})
                                            </span>
                                          )}
                                      </td>
                                      <td>
                                        {convertNumber(subProj.e7)} <br />
                                        {parseInt(subProj.mainE7) !== 0 &&
                                          subProj.mainE7 !== subProj.e7 && (
                                            <span className="subAmount">
                                              ({convertNumber(subProj.mainE7)})
                                            </span>
                                          )}
                                      </td>
                                      <td>
                                        {convertNumber(subProj.e8)} <br />
                                        {parseInt(subProj.mainE8) !== 0 &&
                                          subProj.mainE8 !== subProj.e8 && (
                                            <span className="subAmount">
                                              ({convertNumber(subProj.mainE8)})
                                            </span>
                                          )}
                                      </td>
                                      <td>
                                        {convertNumber(subProj.e9)} <br />
                                        {parseInt(subProj.mainE9) !== 0 &&
                                          subProj.mainE9 !== subProj.e9 && (
                                            <span className="subAmount">
                                              ({convertNumber(subProj.mainE9)})
                                            </span>
                                          )}
                                      </td>
                                      <td>
                                        {convertNumber(subProj.e10)} <br />
                                        {parseInt(subProj.mainE10) !== 0 &&
                                          subProj.mainE10 !== subProj.e10 && (
                                            <span className="subAmount">
                                              ({convertNumber(subProj.mainE10)})
                                            </span>
                                          )}
                                      </td>
                                    </>
                                  )}
                                  <td
                                    onClick={() =>
                                      setActiveSubSub((prev) => {
                                        if (prev.includes(subProj.pnr)) {
                                          // Fjern prosjektet hvis det allerede er i listen
                                          return prev.filter(
                                            (name) => name !== subProj.pnr
                                          );
                                        } else {
                                          // Legg til prosjektet hvis det ikke er i listen
                                          return [...prev, subProj.pnr];
                                        }
                                      })
                                    }
                                  >
                                    <>
                                      {pinList.find(
                                        (item) => item.pnr === subProj.pnr
                                      ) ? (
                                        <i
                                          className="bi bi-pin-angle-fill"
                                          onClick={() =>
                                            setPinList((prev) =>
                                              prev.filter(
                                                (item) =>
                                                  item.pnr !== subProj.pnr
                                              )
                                            )
                                          }
                                        />
                                      ) : (
                                        <i
                                          className="bi bi-pin-angle"
                                          onClick={() =>
                                            setPinList((prev) => [
                                              ...prev,
                                              {
                                                pnr: subProj.pnr,
                                                parentId: index,
                                                subId: subIndex,
                                                subsubId: null,
                                              },
                                            ])
                                          }
                                        />
                                      )}
                                      <br />

                                      <ProjectHistory project={subProj.pnr}>
                                        <i
                                          className="bi bi-chat-quote"
                                          aria-expanded="false"
                                        />
                                      </ProjectHistory>
                                      {parseInt(subProj.reportOpen) ? (
                                        <>
                                          {parseInt(subProj.reportAccepted) ? (
                                            <i
                                              style={{ color: "green" }}
                                              className="bi bi-circle-fill"
                                            />
                                          ) : (
                                            <>
                                              {parseInt(subProj.reportSent) ? (
                                                <i
                                                  style={{ color: "yellow" }}
                                                  className="bi bi-circle-fill"
                                                />
                                              ) : (
                                                <i
                                                  style={{ color: "red" }}
                                                  className="bi bi-circle-fill"
                                                />
                                              )}
                                            </>
                                          )}
                                        </>
                                      ) : (
                                        /* Rapporten er ikke åpnet */
                                        <>
                                          {parseInt(subProj.reportAccepted) ? (
                                            <i
                                              style={{ color: "green" }}
                                              className="bi bi-circle-fill"
                                            />
                                          ) : (
                                            " "
                                          )}
                                        </>
                                      )}
                                    </>
                                  </td>
                                </tr>
                                {subProj?.subData?.map(
                                  (subsubProj, subsubIndex) => {
                                    return (
                                      <tr
                                        key={subsubIndex}
                                        className={`
                                          ${
                                            pinList.find(
                                              (item) =>
                                                item.pnr === subsubProj.pnr
                                            )
                                              ? `subsubProject 
                                            
                                            pinned pinCount${
                                              pinList.find(
                                                (item) =>
                                                  item.pnr === subsubProj.pnr
                                              )?.count
                                            }`
                                              : `subsubProject
                                           
                                            enhet-${subsubProj.enhet}`
                                          }
                                        `}
                                        style={
                                          activeSub.includes(proj.name) &&
                                          activeSubSub.includes(subProj.pnr)
                                            ? {
                                                display: "table-row",
                                                height: "110px",
                                                top: pinList.find(
                                                  (prev) =>
                                                    prev.pnr === subsubProj.pnr
                                                )
                                                  ? pinHeight +
                                                    parseInt(
                                                      parseInt(
                                                        sortedPinList.findIndex(
                                                          (item) =>
                                                            item.pnr ===
                                                            subsubProj.pnr
                                                        )
                                                      ) * 105
                                                    ) +
                                                    "px"
                                                  : 0, // Sett toppen til 0 hvis elementet ikke er festet
                                              }
                                            : { display: "none" }
                                        }
                                      >
                                        <td colSpan={2}>
                                          <div
                                            className="d-flex flex-column"
                                            style={{ marginLeft: 45 }}
                                          >
                                            {subsubProj.pnr} -{" "}
                                            {subsubProj.pname}
                                            <span className="projType">
                                              <img
                                                alt=""
                                                src={proj.img}
                                                height={15}
                                                width={15}
                                              />{" "}
                                              {subProj.pnr} - {subProj.pname}
                                            </span>
                                          </div>
                                        </td>
                                        <td>{formatName(subsubProj.pl)}</td>
                                        <td>{convertNumber(subsubProj.e1)}</td>
                                        <td>{convertNumber(subsubProj.e2)}</td>
                                        <td>{convertNumber(subsubProj.e3)}</td>
                                        <td>{convertNumber(subsubProj.e4)}</td>
                                        {bigTable && (
                                          <>
                                            <td>
                                              {convertNumber(subsubProj.e5)}
                                            </td>
                                            <td>
                                              {convertNumber(subsubProj.e6)}
                                            </td>
                                            <td>
                                              {convertNumber(subsubProj.e7)}
                                            </td>
                                            <td>
                                              {convertNumber(subsubProj.e8)}
                                            </td>
                                            <td>
                                              {convertNumber(subsubProj.e9)}
                                            </td>
                                            <td>
                                              {convertNumber(subsubProj.e10)}
                                            </td>
                                          </>
                                        )}
                                        <td style={{ cursor: "pointer" }}>
                                          <>
                                            {pinList.find(
                                              (item) =>
                                                item.pnr === subsubProj.pnr
                                            ) ? (
                                              <i
                                                className="bi bi-pin-angle-fill"
                                                onClick={() =>
                                                  setPinList((prev) =>
                                                    prev.filter(
                                                      (item) =>
                                                        item.pnr !==
                                                        subsubProj.pnr
                                                    )
                                                  )
                                                }
                                              />
                                            ) : (
                                              <i
                                                className="bi bi-pin-angle"
                                                onClick={() =>
                                                  setPinList((prev) => [
                                                    ...prev,
                                                    {
                                                      pnr: subsubProj.pnr,
                                                      parentId: index,
                                                      subId: subIndex,
                                                      subsubId: subsubIndex,
                                                    },
                                                  ])
                                                }
                                              />
                                            )}{" "}
                                            <br />
                                            <ProjectHistory
                                              project={subsubProj.pnr}
                                            >
                                              <i
                                                className="bi bi-chat-quote"
                                                aria-expanded="false"
                                              />
                                            </ProjectHistory>
                                            {parseInt(subsubProj.reportOpen) ? (
                                              <>
                                                {parseInt(
                                                  subsubProj.reportAccepted
                                                ) ? (
                                                  <i
                                                    style={{ color: "green" }}
                                                    className="bi bi-circle-fill"
                                                  />
                                                ) : (
                                                  <>
                                                    {parseInt(
                                                      subsubProj.reportSent
                                                    ) ? (
                                                      <i
                                                        style={{
                                                          color: "yellow",
                                                        }}
                                                        className="bi bi-circle-fill"
                                                      />
                                                    ) : (
                                                      <i
                                                        style={{ color: "red" }}
                                                        className="bi bi-circle-fill"
                                                      />
                                                    )}
                                                  </>
                                                )}
                                              </>
                                            ) : (
                                              /* Rapporten er ikke åpnet */
                                              <>
                                                {parseInt(
                                                  subsubProj.reportAccepted
                                                ) ? (
                                                  <i
                                                    style={{ color: "green" }}
                                                    className="bi bi-circle-fill"
                                                  />
                                                ) : null}
                                              </>
                                            )}
                                          </>
                                        </td>
                                      </tr>
                                    );
                                  }
                                )}
                              </React.Fragment>
                            );
                          })}
                        </React.Fragment>
                      );
                    })}
                  </tbody>
                </Table>
              </div>

              <div id="projectTableFooter">
                <div className="d-flex justify-content-between">
                  <div className="p-2 d-flex flex-row">
                    <span className="m-2 pt-1">
                      <i className="bi bi-funnel" /> Filter
                    </span>
                    <Dropdown autoClose="outside" className="m-2" drop="up">
                      <Dropdown.Toggle
                        variant={
                          mainProjFilter.length > 0 ? "info" : "secondary"
                        }
                        size="sm"
                        id="dropdown-basic"
                      >
                        Hovedprosjekt
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        {mainProjects?.map((type, k) => {
                          return (
                            <Dropdown.Item
                              key={k}
                              onClick={() => {
                                setMainProjFilter((prev) => {
                                  /*
                                  const curCookie = getCookie("filteredTypes");
                                  let newCookies = [];

                                  if (curCookie) {
                                    for (let i = 0; i < curCookie.length; i++) {
                                      if (curCookie[i] !== d.name) {
                                        newCookies.push(curCookie[i]);
                                      }
                                    }
                                  }
                                    */

                                  if (prev.includes(type)) {
                                    //setCookie("filteredTypes", newCookies);
                                    return prev.filter((item) => item !== type);
                                  } else {
                                    //newCookies.push(d.name);
                                    //setCookie("filteredTypes", newCookies);
                                    return [...prev, type];
                                  }
                                });
                              }}
                            >
                              {mainProjFilter.includes(type) ? (
                                <i
                                  className="bi bi-bookmark-check"
                                  style={{ color: "blue" }}
                                />
                              ) : (
                                <i
                                  className="bi bi-bookmark-x"
                                  style={{ color: "grey" }}
                                />
                              )}
                              {type}{" "}
                            </Dropdown.Item>
                          );
                        })}
                      </Dropdown.Menu>
                    </Dropdown>

                    <Dropdown
                      autoClose="outside"
                      className="m-2"
                      style={{ marginRight: 5 }}
                      drop="up"
                    >
                      <Dropdown.Toggle
                        variant={plProjFilter.length > 0 ? "info" : "secondary"}
                        size="sm"
                        id="dropdown-basic"
                      >
                        Prosjektleder
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        {PLinProjects.map((pl, plKey) => {
                          return (
                            <Dropdown.Item
                              key={plKey}
                              onClick={() => {
                                setPlProjFilter((prev) => {
                                  if (prev.includes(pl)) {
                                    return prev.filter((item) => item !== pl);
                                  } else {
                                    return [...prev, pl];
                                  }
                                });
                              }}
                            >
                              {plProjFilter.includes(pl) ? (
                                <i
                                  className="bi bi-bookmark-check"
                                  style={{ color: "blue" }}
                                />
                              ) : (
                                <i
                                  className="bi bi-bookmark-x"
                                  style={{ color: "grey" }}
                                />
                              )}
                              {pl}{" "}
                            </Dropdown.Item>
                          );
                        })}
                      </Dropdown.Menu>
                    </Dropdown>

                    <div
                      style={{
                        width: 1,
                        borderRight: "1px #000 dotted",
                        marginLeft: 15,
                        marginRight: 15,
                      }}
                      className={`back-to-top ${
                        plProjFilter?.length !== 0 ||
                        mainProjFilter?.length !== 0
                          ? "active"
                          : ""
                      }`}
                    />
                    <Button
                      size="sm"
                      variant="warning"
                      className={`m-2 hidden ${
                        plProjFilter?.length !== 0 ||
                        mainProjFilter?.length !== 0
                          ? "show"
                          : ""
                      }`}
                      onClick={clearFilters}
                    >
                      Fjern filter
                    </Button>
                    <div
                      style={{
                        width: 1,
                        borderRight: "1px #000 dotted",
                        marginLeft: 15,
                        marginRight: 15,
                      }}
                    />
                    <Button
                      variant="dark"
                      size="sm"
                      className="m-2"
                      onClick={toggleTableMode}
                    >
                      {isBrutto ? "Bruttovisning" : "Nettovisning"}
                    </Button>
                  </div>
                  <div className="p-2 d-flex flex-row">
                    <Button
                      size="sm"
                      className={`m-2 back-to-top ${
                        activeSub?.length !== 0 ? "active" : ""
                      }`}
                      variant="warning"
                      onClick={() => {
                        setActiveSub([]);
                        setActiveSubSub([]);
                      }}
                    >
                      Lukk alle prosjekter
                    </Button>
                    <div
                      className={`back-to-top me-5  ${
                        backToTopVisible ? "active" : ""
                      }`}
                    >
                      <div
                        style={{
                          backgroundColor: "#47b2e4",
                          borderRadius: 50,
                          width: 40,
                          height: 40,
                          cursor: "pointer",
                        }}
                        className={`d-flex align-items-center justify-content-center`}
                        onClick={() =>
                          window.scrollTo({ top: 0, behavior: "smooth" })
                        }
                      >
                        <i
                          className="bi bi-arrow-up-short"
                          style={{ color: "white" }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </>
      ) : (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: "100%" }}
        >
          <Spinner animation="grow" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      )}
    </>
  );
}
