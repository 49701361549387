import React, { useEffect, useState } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import { Alert, Breadcrumb, Button, Table } from "react-bootstrap";
import LogUser from "../../../hooks/log";

export default function Version() {
  const navigate = useNavigate();
  const params = useParams();
  const [type, setType] = useState(null); // Valgt type
  const [types, setTypes] = useState([]); // Alle typer

  const [data, setData] = useState([]); // Data fra type

  // Funksjoner
  const [newVersion, setNewVersion] = useState(false);
  const [newVersionDesc, setNewVersionDesc] = useState("");

  const [newYear, setNewYear] = useState(false);
  const [newYearDesc, setNewYearDesc] = useState("Opprinnelig budsjett");

  useEffect(() => {
    const getTypes = async () => {
      try {
        const res = await fetch(
          "https://prosjekt.tkva.no/api/admin/types.php?typeData=versions",
          { credentials: "include" }
        );
        const data = await res.json();
        if (data?.[0]?.id) {
          if (!params.typeId) {
            setType(data[0].id);
            navigate("" + data[0].id, { replace: true });
          }
        }
        setTypes(data);
      } catch (err) {
        return;
      }
    };
    getTypes();
  }, []);

  useEffect(() => {
    setType(params.typeId);
    if (params.typeId) {
      getData();
    }
    return () => {
      setData([]);
    };
  }, [params.typeId]);

  const getData = async () => {
    try {
      const res = await fetch(
        "https://prosjekt.tkva.no/api/admin/versions.php?id=" + params.typeId,
        { credentials: "include" }
      );
      const data = await res.json();
      setData(data);
    } catch (err) {
      return;
    }
  };

  const toggleOpen = async (open, version, year) => {
    const newOpen = parseInt(open) ? 0 : 1;
    const postData = {
      action: "toggleOpen",
      type_id: type,
      version: version,
      open: newOpen,
      year: year,
    };
    try {
      const res = await fetch(
        "https://prosjekt.tkva.no/api/admin/versions.php",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(postData),
          credentials: "include",
        }
      );
      if (!res.ok) {
        alert("Noe gikk galt.. dessverre");
      } else {
        getData();
        LogUser(
          "Versjoner",
          "Brukeren har endret rapporteringsmodus på typeid: " +
            type +
            " og versjon: " +
            version
        );
      }
    } catch (err) {
      alert("Noe gikk galt: " + err.message);
    }
  };

  const editDate = async (date, version, year) => {
    const postData = {
      action: "editDate",
      type_id: type,
      version: version,
      date: date,
      year: year,
    };
    try {
      const res = await fetch(
        "https://prosjekt.tkva.no/api/admin/versions.php",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(postData),
          credentials: "include",
        }
      );
      if (!res.ok) {
        alert("Noe gikk galt.. dessverre");
      } else {
        getData();
        LogUser(
          "Versjoner",
          "Brukeren har endret noe på typeid: " +
            type +
            " og versjon: " +
            version
        );
      }
    } catch (err) {
      alert("Noe gikk galt: " + err.message);
    }
  };

  const saveNewVersion = async () => {
    const postData = {
      action: "newVersion",
      type_id: type,
      desc: newVersionDesc,
    };
    try {
      const res = await fetch(
        "https://prosjekt.tkva.no/api/admin/versions.php",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(postData),
          credentials: "include",
        }
      );
      if (!res.ok) {
        alert("Noe gikk galt.. dessverre");
      } else {
        getData();
        LogUser(
          "Versjoner",
          "Brukeren har opprettet ny versjon for type_id: " + type
        );
      }
    } catch (err) {
      alert("Noe gikk galt: " + err.message);
    }
    setNewVersion(false);
    getData();
  };

  const deleteVersion = async () => {
    const postData = {
      action: "deleteVersion",
      type_id: type,
    };
    try {
      const res = await fetch(
        "https://prosjekt.tkva.no/api/admin/versions.php",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(postData),
          credentials: "include",
        }
      );
      if (!res.ok) {
        alert("Noe gikk galt.. dessverre");
      } else {
        alert("Versjonen er slettet!");
        LogUser(
          "Versjoner",
          "Brukeren har slettet versjon for type_id: " + type
        );
        getData();
      }
    } catch (err) {
      alert("Noe gikk galt: " + err.message);
    }
    getData();
  };

  const saveNewYear = async () => {
    const postData = {
      action: "newYear",
      type_id: type,
      desc: newYearDesc,
    };
    try {
      const res = await fetch(
        "https://prosjekt.tkva.no/api/admin/versions.php",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(postData),
          credentials: "include",
        }
      );
      if (!res.ok) {
        alert("Noe gikk galt.. dessverre");
      } else {
        getData();
        LogUser(
          "Versjoner",
          "Brukeren har opprettet ny regnskapsår for type_id: " + type
        );
      }
    } catch (err) {
      alert("Noe gikk galt: " + err.message);
    }
    setNewVersion(false);
    getData();
  };

  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link to="../admin">Admin</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item active>
          <Link to="../admin/project/version">Versjoner</Link>
        </Breadcrumb.Item>
      </Breadcrumb>
      <div className="row">
        <div className="d-flex justify-content-between">
          <div className="col-8">
            <h5>Versjonsadministrasjon</h5>
            <div className="content">
              {!type ? (
                <div className="alert alert-warning" role="alert">
                  Du må velge type i høyre marg
                </div>
              ) : (
                <div className="row">
                  <div className="col-lg-8">
                    <h4>{data[0]?.name ? data[0].name : "Laster..."}</h4>
                    <hr />
                    <div className="table-responsive">
                      <Table>
                        <thead>
                          <tr>
                            <th>Versjon</th>
                            <th>Beskrivelse</th>
                            <th>Frist</th>
                            <th>Åpen</th>
                            <th>Årstall</th>
                            <th>#</th>
                          </tr>
                        </thead>
                        <tbody>
                          {data.map((d, dIndex) => {
                            return (
                              <tr key={dIndex}>
                                <td
                                  className={
                                    parseInt(d.activeVersion) && "bg-success"
                                  }
                                >
                                  {d.version}
                                </td>
                                <td
                                  className={
                                    parseInt(d.activeVersion) && "bg-success"
                                  }
                                >
                                  {d.comment}
                                </td>
                                <td
                                  className={
                                    parseInt(d.activeVersion) && "bg-success"
                                  }
                                >
                                  <input
                                    type="date"
                                    defaultValue={d.endDate}
                                    onChange={(e) =>
                                      editDate(
                                        e.target.value,
                                        d.version,
                                        d.year
                                      )
                                    }
                                  />
                                </td>
                                <td
                                  className={
                                    parseInt(d.activeVersion) && "bg-success"
                                  }
                                >
                                  {d.open}{" "}
                                  <i
                                    className="bi bi-toggles"
                                    onClick={() =>
                                      toggleOpen(d.open, d.version, d.year)
                                    }
                                  />
                                </td>
                                <td
                                  className={
                                    parseInt(d.activeVersion) && "bg-success"
                                  }
                                >
                                  {d.year}
                                </td>
                                {dIndex === 0 ? (
                                  <td
                                    onClick={() => deleteVersion()}
                                    style={{ cursor: "pointer" }}
                                    className="bg-warning"
                                  >
                                    Slett
                                  </td>
                                ) : (
                                  <td>&nbsp;</td>
                                )}
                              </tr>
                            );
                          })}
                        </tbody>
                      </Table>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <h6>Funksjoner</h6>
                    <hr />
                    {newVersion && (
                      <>
                        <div className="d-flex flex-column">
                          <input
                            type="text"
                            style={{
                              width: "100%",
                              border: 0,
                              textAlign: "center",
                              marginBottom: 5,
                            }}
                            autoFocus
                            onChange={(e) => setNewVersionDesc(e.target.value)}
                            placeholder="Beskrivelse"
                          />
                          <div className="d-flex justify-content-between">
                            <Button
                              size="sm"
                              variant="secondary"
                              style={{ width: "50%" }}
                              onClick={() => {
                                setNewVersion(false);
                              }}
                            >
                              Avbryt
                            </Button>
                            <Button
                              size="sm"
                              variant="success"
                              autoFocus
                              style={{ width: "45%" }}
                              onClick={() => {
                                saveNewVersion();
                              }}
                            >
                              Opprett versjon
                            </Button>
                          </div>
                        </div>
                      </>
                    )}

                    {newYear && (
                      <>
                        <div className="d-flex flex-column">
                          <Alert>
                            <i
                              className="bi bi-info float-left"
                              style={{ fontSize: 24 }}
                            />
                            Nytt regnskapsår tar utgangspunkt i siste versjon i
                            dette år
                          </Alert>
                          <input
                            type="text"
                            style={{
                              width: "100%",
                              border: 2,
                              borderRadius: 5,
                              borderColor: "black",
                              textAlign: "center",
                              marginBottom: 5,
                            }}
                            defaultValue={newYearDesc}
                            autoFocus
                            onChange={(e) => setNewYearDesc(e.target.value)}
                            placeholder="Beskrivelse"
                          />
                          <div className="d-flex justify-content-between">
                            <Button
                              size="sm"
                              variant="secondary"
                              style={{ width: "50%" }}
                              onClick={() => {
                                setNewYear(false);
                              }}
                            >
                              Avbryt
                            </Button>
                            <Button
                              size="sm"
                              variant="success"
                              autoFocus
                              style={{ width: "45%" }}
                              onClick={() => {
                                saveNewYear();
                              }}
                            >
                              Opprett nytt år
                            </Button>
                          </div>
                        </div>
                      </>
                    )}

                    {!newVersion && !newYear && (
                      <>
                        <Button
                          size="sm"
                          variant="primary"
                          style={{ width: "100%", marginBottom: 5 }}
                          onClick={() => {
                            setNewVersion(true);
                          }}
                        >
                          Ny versjon
                        </Button>
                        <Button
                          size="sm"
                          variant="secondary"
                          style={{ width: "100%", marginBottom: 5 }}
                          onClick={() => {
                            setNewYear(true);
                          }}
                        >
                          Nytt regnskapsår
                        </Button>
                      </>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="col-3">
            <h5>Prosjekttyper</h5>
            <div className="list-group">
              {types.map((type, typeIndex) => {
                return (
                  <Link
                    key={typeIndex}
                    onClick={() => setType(type.id)}
                    to={"/admin/project/version/" + type.id}
                    className={`list-group-item list-group-item-action ${
                      parseInt(type.id) === parseInt(type) && "active"
                    }`}
                  >
                    {type.name}
                  </Link>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
