import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";
import { getCookie, setCookie } from "../../utils/cookie";

const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const navigate = useNavigate();
  const [auth, setAuth] = useState(false);
  const [permissions, setPermissions] = useState([]);
  const [loggingIn, setLoggingIn] = useState(false);
  const [theme, setTheme] = useState("light");

  const [userName, setUserName] = useState(null);
  const [userId, setUserId] = useState(null);

  // Utviklingsmodus
  const [developement, setDevelopement] = useState(false);

  const login = async (email, password) => {
    setLoggingIn(true);

    const postData = {
      action: "login",
      email: email,
      password: password,
    };

    try {
      const res = await fetch("https://prosjekt.tkva.no/api/auth.php", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(postData),
        credentials: "include",
      });

      if (res.ok) {
        const result = await res.json();
        if (result.auth) {
          if (result.roles) {
            setPermissions(result.roles);
          }
          navigate("../");
          setAuth(true);
          setUserName(result.userName);
          setUserId(result.userId);
          setLoggingIn(false);
        } else {
          alert("Feil brukernavn eller passord");
          setLoggingIn(false);
          setAuth(false);
        }
      }
    } catch (err) {
      alert("Noe gikk galt\n" + err.message);
      setLoggingIn(false);
    }

    return () => {
      setLoggingIn(false);
    };
  };
  const logout = async () => {
    try {
      await fetch("https://prosjekt.tkva.no/api/logout.php", {
        credentials: "include",
      });
      navigate("../login");
    } catch (err) {
      navigate("../login");
    }
  };

  const refreshAuth = async () => {
    try {
      const res = await fetch("https://prosjekt.tkva.no/api/auth.php", {
        credentials: "include",
      });
      if (res.ok) {
        const result = await res.json();
        if (result.auth) {
          setAuth(true);
          setUserName(result.userName);
          setUserId(result.userId);
          if (result.roles) {
            setPermissions(result.roles);
          }
          if (parseInt(result.dev)) {
            setDevelopement(true);
          } else {
            setDevelopement(false);
          }
        } else {
          setAuth(false);
          navigate("../login");
        }
      }
    } catch (err) {
      console.log(err.message);
      navigate("../login");
    }
  };

  useEffect(() => {
    if (getCookie("theme")) {
      setTheme(getCookie("theme"));
    }

    refreshAuth(); // Initial

    const refreshInt = setInterval(() => {
      refreshAuth();
    }, 120000);

    return () => {
      clearInterval(refreshInt);
    };
  }, []);

  useEffect(() => {
    const date = new Date();
    date.setFullYear(date.getFullYear() + 1); // Legger til 1 år fra nå

    if (theme === "light") {
      setCookie("theme", "light", {
        expires: date,
      });
      document.documentElement.setAttribute("data-bs-theme", "light");
    }
    if (theme === "dark") {
      setCookie("theme", "dark", {
        expires: date,
      });
      document.documentElement.setAttribute("data-bs-theme", "dark");
    }
  }, [theme]);

  const value = useMemo(
    () => ({
      login,
      logout,
      loggingIn,
      permissions,
      refreshAuth,
      developement,
      auth,
      theme,
      setTheme,
    }),
    [loggingIn, permissions, developement, auth, theme]
  );

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

export const useAuth = () => {
  return useContext(UserContext);
};
