import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import LogUser from "../../../hooks/log";

export default function PL() {
  const [pl, setPl] = useState([]);
  const [plMissing, setPlMissing] = useState([]);
  const [hasPl, setHasPl] = useState([]);

  // Add pleader
  const [pickedProj, setPickedProj] = useState(null);
  const [pickedProjName, setPickedProjName] = useState(null);

  // Expand projectleader
  const [expanedUsers, setExpandedUsers] = useState([]);

  useEffect(() => {
    getPL();
    getPlMissing();
    getHasPl();

    const handleEsc = (event) => {
      if (event.key === "Escape") {
        setPickedProj(null);
        setPickedProjName(null);
      }
    };
    window.addEventListener("keydown", handleEsc);

    return () => {
      window.removeEventListener("keydown", handleEsc);
    };
  }, []);

  const getPL = async () => {
    try {
      const res = await fetch("https://prosjekt.tkva.no/api/admin/pl.php?pl", {
        credentials: "include",
      });
      const data = await res.json();
      setPl(data);
    } catch (err) {
      return;
    }
  };
  const getPlMissing = async () => {
    try {
      const res = await fetch(
        "https://prosjekt.tkva.no/api/admin/pl.php?plMissing",
        { credentials: "include" }
      );
      const data = await res.json();
      setPlMissing(data);
    } catch (err) {
      return;
    }
  };
  const getHasPl = async () => {
    try {
      const res = await fetch(
        "https://prosjekt.tkva.no/api/admin/pl.php?hasPl",
        { credentials: "include" }
      );
      const data = await res.json();
      setHasPl(data);
    } catch (err) {
      return;
    }
  };

  const addPL = async (userId) => {
    const postData = {
      action: "addPL",
      userId: userId,
      mainPL: pl?.filter((p) => p.proj.some((proj) => proj.pnr === pickedProj))
        .length
        ? 0
        : 1,
      pnr: pickedProj,
    };
    try {
      const res = await fetch("https://prosjekt.tkva.no/api/admin/pl.php", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(postData),
        credentials: "include",
      });
      if (!res.ok) {
        alert("Noe gikk galt.. dessverre");
      } else {
        getPL();
        getPlMissing();
        getHasPl();
        LogUser(
          "Prosjektleder",
          "Brukeren har lagt til prosjektleder på prosjektet " + pickedProj
        );
      }
    } catch (err) {
      alert("Noe gikk galt: " + err.message);
    }
  };

  const removePL = async (userId) => {
    const postData = {
      action: "removePL",
      userId: userId,
      pnr: pickedProj,
    };
    try {
      const res = await fetch("https://prosjekt.tkva.no/api/admin/pl.php", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(postData),
        credentials: "include",
      });
      if (!res.ok) {
        alert("Noe gikk galt.. dessverre");
      } else {
        getPL();
        getPlMissing();
        getHasPl();
        LogUser(
          "Prosjektleder",
          "Brukeren har fjernet prosjektleder på prosjektet " + pickedProj
        );
      }
    } catch (err) {
      alert("Noe gikk galt: " + err.message);
    }
  };

  return (
    <>
      <div className="row" style={{ height: "100%", width: "100%" }}>
        <div
          className="col-12"
          style={{
            maxHeight: "400px",
            overflow: "auto",
            display: pickedProj ? "none" : "",
          }}
          //style={pickedProj ? {} : { display: "none" }}
        >
          <h4>Prosjekter</h4>
          <Table variant="warning">
            <thead style={{ position: "sticky", top: 0 }}>
              <tr>
                <th>Prosjekter uten PL</th>
              </tr>
            </thead>
            <tbody>
              {plMissing.map((pMiss, pMIndex) => {
                return (
                  <tr
                    key={pMIndex}
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setPickedProj(pMiss.pnr);
                      setPickedProjName(pMiss.pname);
                    }}
                  >
                    <td>
                      {pMiss.pnr} - {pMiss.pname}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
          <Table>
            <thead style={{ position: "sticky", top: 0 }}>
              <tr>
                <th>Prosjekter</th>
              </tr>
            </thead>
            <tbody>
              {hasPl.map((pHas, pHIndex) => {
                return (
                  <tr
                    key={pHIndex}
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setPickedProj(pHas.pnr);
                      setPickedProjName(pHas.pname);
                    }}
                  >
                    <td>
                      {pHas.pnr} - {pHas.pname}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
        <div
          className="col-12 row mt-4"
          style={pickedProj ? {} : { display: "none" }}
        >
          <h6 className="bg-heading-1 p-2 mx-2">
            Valg prosjekt: {pickedProj} - {pickedProjName}
          </h6>
          <span className="text-muted">Trykk "ESC" for å avbryte</span>
          <div className="col-5">
            <h5>Prosjektleder</h5>
            <Table>
              <thead style={{ position: "sticky", top: 0 }}>
                <tr>
                  <th>Prosjektleder</th>
                  <th>&nbsp;</th>
                </tr>
              </thead>
              <tbody>
                {pl
                  ?.filter((p) =>
                    p.proj.some((proj) => proj.pnr === pickedProj)
                  ) // Filtrerer basert på pnr
                  .map((p) => (
                    <tr key={p.id}>
                      <td>{p.name}</td>
                      <td
                        style={{ textAlign: "right", cursor: "pointer" }}
                        onClick={() => removePL(p.id)}
                      >
                        <i className="bi bi-x-circle text-danger" />
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </div>
          <div className="col-2">
            <span>&nbsp;</span>
          </div>
          <div className="col-5 overflow-auto" style={{ maxHeight: "600px" }}>
            <h5>Tilgjengelige prosjektledere</h5>
            <Table>
              <thead style={{ position: "sticky", top: 0 }}>
                <tr>
                  <th>Prosjektleder</th>
                  <th>&nbsp;</th>
                </tr>
              </thead>
              <tbody>
                {pl
                  ?.filter(
                    (p) => !p.proj.some((proj) => proj.pnr === pickedProj)
                  ) // Filtrerer basert på pnr
                  .map((p) => (
                    <tr key={p.id}>
                      <td
                        style={{ textAlign: "left", cursor: "pointer" }}
                        onClick={() => addPL(p.id)}
                      >
                        <i className="bi-plus-circle-dotted text-success" />
                      </td>
                      <td style={{ textAlign: "right" }}>{p.name}</td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </div>
        </div>
      </div>
      <div className="row d-none">
        <div className="col-8">
          {plMissing && (
            <>
              <h5>Prosjekter uten prosjektleder ({plMissing.length})</h5>
              <div
                className="content"
                style={{ maxHeight: "40vh", overflowY: "auto" }}
              >
                {plMissing.map((p, pIndex) => {
                  return (
                    <div
                      className="col m-2"
                      key={pIndex}
                      style={{
                        cursor: "pointer",
                        backgroundColor: "lightgrey",
                        padding: 5,
                        ...(pickedProj && { filter: "blur(1px)" }),
                      }}
                      onClick={() => setPickedProj(p.pnr)}
                    >
                      {p.pnr} - {p.pname}
                    </div>
                  );
                })}
              </div>
            </>
          )}
          <div className="m-4" />
          <h5>Prosjekter med prosjektleder ({hasPl.length})</h5>
          <div
            className="content"
            style={{ maxHeight: "40vh", overflowY: "auto" }}
          >
            {hasPl.map((p, pIndex) => {
              return (
                <div className="col m-2" key={pIndex}>
                  {p.pnr} - {p.pname}
                </div>
              );
            })}
          </div>
        </div>
        <div className="col-3">
          <h6>Brukere</h6>
          {pl.map((p, pIndex) => {
            return (
              <div
                className="d-flex flex-column"
                key={pIndex}
                style={{
                  borderBottom: "1px #000 solid",
                  paddingBottom: 5,
                  marginBottom: 5,
                }}
              >
                <div className="d-flex">
                  <div className="p-2">
                    <i
                      className="bi bi-person-badge"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setExpandedUsers((prev) => {
                          if (prev.includes(p.name)) {
                            return prev.filter((name) => name !== p.name);
                          } else {
                            return [...prev, p.name];
                          }
                        });
                      }}
                    />
                  </div>
                  <div className="p-2 d-flex flex-row justify-content-between w-100">
                    <Link to={"/admin/users/" + p.id}>{p.name}</Link>
                    {pickedProj && (
                      <div>
                        <i
                          className="bi bi-plus-circle-dotted"
                          style={{ cursor: "pointer" }}
                          onClick={() => addPL(p.id, p.name)}
                        />
                      </div>
                    )}
                  </div>
                </div>
                {p.proj.length > 0 && (
                  <div
                    className={`d-flex ${
                      !expanedUsers.includes(p.name) && "d-none"
                    }`}
                    style={{ backgroundColor: "lightgrey", borderRadius: 2 }}
                  >
                    <div className="p-2">
                      <i className="bi bi-buildings" />
                    </div>
                    <div className="p-2 text-dark">
                      {p.proj.map((proj, projIndex) => {
                        return (
                          <span key={projIndex}>
                            <Link to={"/admin/project/edit/" + proj.pnr}>
                              {proj.pnr} - {proj.pname}
                            </Link>
                            ,{" "}
                          </span>
                        );
                      })}
                    </div>
                  </div>
                )}
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
}
