import React, { useEffect, useState } from "react";
import { Button, Table } from "react-bootstrap";
import { useParams } from "react-router-dom";
import ProjectHistory from "../projects/history";
import SubProjReportStatus from "./SubProjects";
import { FaseDisplay } from "./FaseDisplay";
import LogUser from "../../hooks/log";

export default function Reports() {
  const params = useParams();

  return (
    <>
      {params.pnr ? <ReportDashboard pnr={params.pnr} /> : <ReportOverview />}
    </>
  );
}

const ReportOverview = () => {
  return (
    <>
      <h5>Prosjektoversikt</h5>
      <div className="content">Her kommer prosjekter og status</div>
    </>
  );
};

const convertNumber = (number) => {
  let num = parseInt(number);
  if (isNaN(num)) {
    return 0;
  }
  return num.toLocaleString();
};

const ReportDashboard = ({ pnr }) => {
  const [data, setData] = useState([]);
  const [dis, setDis] = useState(false); // Rapporten er allerede sent
  const [reportClosed, setReportClosed] = useState(false); /// Rapporteringen er stengt

  const [changesMade, setChangesMade] = useState(false); // Godkjente endringer
  const [phases, setPhases] = useState([]); // Faser

  // Underprosjekt
  const [subProjReportStatusShow, setSubProjReportStatusShow] = useState(false);

  useEffect(() => {
    getProjData();
  }, []);

  const getProjData = async () => {
    try {
      const res = await fetch(
        "https://prosjekt.tkva.no/api/reports.php?pnr=" + pnr
      );
      const data = await res.json();
      setData(data);
      setDis(parseInt(data.economy[0].reportSent) ? true : false);
      setReportClosed(parseInt(data?.reportOpen) ? false : true);

      setChangesMade(parseInt(data?.changesMade) > 1 ? true : false);
      setPhases(data?.phaseDetails);
      LogUser(
        "Rapportering",
        "Brukeren åpnet rapportering for prosjekt " + pnr
      );
    } catch (err) {
      return;
    }
  };

  const saveKladd = async () => {
    if (reportClosed) {
      alert("Rapporteringsvinduet er stengt");
      return;
    }
    const postData = {
      action: "KLADD",
      data: data.economy,
      phases: phases,
    };
    try {
      const res = await fetch("https://prosjekt.tkva.no/api/reports.php", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(postData),
      });
      if (!res.ok) {
        alert("Noe gikk galt.. dessverre");
      } else {
        alert("Endringer er lagret");
        LogUser(
          "Rapportering",
          "Brukeren kladdet rapportering for prosjekt " + pnr
        );
      }
    } catch (err) {
      alert("Noe gikk galt..");
    }
  };

  const saveReport = async () => {
    if (reportClosed) {
      alert("Rapporteringsvinduet er stengt");
      return;
    }
    const postData = {
      action: "REPORT",
      data: data.economy,
      phases: phases,
    };
    try {
      const res = await fetch("https://prosjekt.tkva.no/api/reports.php", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(postData),
      });
      if (!res.ok) {
        alert("Noe gikk galt.. dessverre");
      } else {
        alert("Rapporten er sendt!");
        LogUser(
          "Rapportering",
          "Brukeren sendte rapportering for prosjekt " + pnr
        );
      }
    } catch (err) {
      alert("Noe gikk galt..");
    }
    getProjData();
  };

  return (
    <>
      <h5 className="p-2 bg-heading-1">Prosjektinfo</h5>
      <div className="d-flex flex-wrap">
        <div className="p-2 align-self-center">
          <i
            className="bi bi-exclamation-octagon"
            style={{ fontSize: 36, color: "#ff840096" }}
          />
        </div>
        <div
          style={{ minWidth: "150px" }}
          className="p-2 mx-4 d-flex flex-column justify-content-center align-items-center"
        >
          <span className="fw-bold">Prosjekt</span>
          <span>
            {data?.pInfo?.[0]?.pnr} - {data?.pInfo?.[0]?.pname}
          </span>
        </div>
        {data?.pInfo?.[0]?.parent && (
          <>
            <div className="border-end" />
            <div
              style={{ minWidth: "150px" }}
              className="p-2 mx-4 d-flex flex-column justify-content-center align-items-center"
            >
              <span className="fw-bold">Hovedprosjekt</span>
              <span>{data?.pInfo?.[0]?.parent}</span>
            </div>
          </>
        )}
        <div className="border-end" />
        <div
          style={{ minWidth: "150px" }}
          className="p-2 mx-4 d-flex flex-column justify-content-center align-items-center"
        >
          <span className="fw-bold">Enhet</span>
          <span>{data?.economy?.[0]?.enhet}</span>
        </div>
        <div className="border-end" />
        <div
          style={{ minWidth: "150px" }}
          className="p-2 mx-4 d-flex flex-column justify-content-center align-items-center"
        >
          <span
            style={{
              border: "1px grey solid",
              borderRadius: 4,
              height: "100%",
              width: "100%",
              cursor: "pointer",
              backgroundColor: "#3333330d",
              marginLeft: 15,
              marginRight: 15,
            }}
            className="align-items-center justify-content-center d-flex hover"
          >
            <ProjectHistory project={data?.pInfo?.[0]?.pnr}>
              <i
                className="bi bi-chat"
                style={{ fontSize: 18, paddingTop: 10 }}
              />{" "}
              <b>Prosjektlogg</b>
            </ProjectHistory>
          </span>
        </div>
        {data?.subProjStatus?.subProjNotReported?.length > 0 && (
          <>
            <div className="border-end" />
            <div className="flex-fill" />
            <div
              style={{
                minWidth: "150px",
                backgroundColor: "#ffa5001f",
                cursor: "pointer",
              }}
              className="p-2 hover mb-2 mx-4 d-flex flex-column justify-content-center align-items-center"
            >
              <SubProjReportStatus
                data={data?.subProjStatus?.subProjNotReported}
                reported={false}
                className={
                  "d-flex flex-column justify-content-center align-items-center"
                }
              >
                <span className="fw-bold">
                  Underprosjekter - ikke rapportert
                </span>
                <span>
                  {data?.subProjStatus?.subProjNotReported?.length} prosjekter
                </span>
              </SubProjReportStatus>
            </div>
            <div className="border-end" />
            <div
              style={{
                minWidth: "150px",
                backgroundColor: "#0080001f",
                cursor: "pointer",
              }}
              className="p-2 hover mb-2 mx-4 d-flex flex-column justify-content-center align-items-center"
            >
              <SubProjReportStatus
                data={data?.subProjStatus?.subProjReported}
                reported={true}
                className={
                  "d-flex flex-column justify-content-center align-items-center"
                }
              >
                <span className="fw-bold">
                  Underprosjekter - har rapportert
                </span>
                <span>
                  {data?.subProjStatus?.subProjReported?.length} prosjekter
                </span>
              </SubProjReportStatus>
            </div>
          </>
        )}
      </div>
      {data?.forbruk?.updated && (
        <>
          <h5 className="p-2 bg-heading-1">Økonomi (tall i tusen)</h5>
          <div className="d-flex flex-wrap">
            <div className="p-2 align-self-center">
              <i
                className="bi bi-piggy-bank-fill"
                style={{ fontSize: 36, color: "#0647069e" }}
              />
            </div>
            <div
              style={{ minWidth: "150px" }}
              className="p-2 mx-4 d-flex flex-column justify-content-center align-items-center"
            >
              <span className="fw-bold">
                Forbruk hittil i år ({data?.economy?.[0]?.year})
              </span>
              <span>{convertNumber(data?.forbruk?.thisForbruk / 1000)}</span>
            </div>
            <div className="border-end" />
            <div
              style={{ minWidth: "150px" }}
              className="p-2 mx-4 d-flex flex-column justify-content-center align-items-center"
            >
              <span className="fw-bold">
                Historisk forbruk før {data?.economy?.[0]?.year}
              </span>
              <span>{convertNumber(data?.forbruk?.oldForbruk / 1000)}</span>
            </div>
            <div className="flex-fill" />
            <span
              className="mx-2 p-2 rounded-1 align-self-center"
              style={{ backgroundColor: "rgba(120, 120, 120, 0.1)" }}
            >
              Økonomidata er oppdatert {data?.forbruk?.updated}
            </span>
          </div>
        </>
      )}
      <hr />
      {dis && (
        <div className="alert alert-success" role="alert">
          {changesMade
            ? "Rapporteringen er godkjent med endring"
            : "Rapporteringen er sendt"}
        </div>
      )}
      {reportClosed && (
        <div className="alert alert-danger" role="alert">
          Rapporteringsvinduet er stengt
        </div>
      )}
      <h6>Økonomiplan</h6>
      <div className="table-responsive">
        <Table>
          <thead>
            <tr>
              <th>Konto (*)</th>
              <th>Tjeneste</th>
              <th>Tema</th>
              {data?.economy?.length > 0 && (
                <>
                  {Array.from({ length: 10 }, (_, index) => {
                    return (
                      <th key={index}>
                        {parseInt(data.economy[0].year) + index}
                      </th>
                    );
                  })}
                </>
              )}
              <th>Totalbudsjett</th>
              <th>Notat</th>
            </tr>
          </thead>
          <tbody>
            {data?.economy?.length > 0 && (
              <>
                {data.economy.map((ec, ecIndex) => {
                  return (
                    <tr key={ecIndex}>
                      <td>
                        <input
                          type="text"
                          disabled={dis}
                          style={{ border: 0, width: "100%" }}
                          defaultValue={ec.konto}
                          onChange={(e) => {
                            setData((prev) => {
                              const updatedEconomy = prev.economy.map(
                                (item, idx) => {
                                  if (idx === ecIndex) {
                                    return {
                                      ...item,
                                      konto: e.target.value,
                                    };
                                  }
                                  return item;
                                }
                              );
                              return {
                                ...prev,
                                economy: updatedEconomy,
                              };
                            });
                          }}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          disabled={dis}
                          style={{ border: 0, width: "100%" }}
                          defaultValue={ec.tjeneste}
                          onChange={(e) => {
                            setData((prev) => {
                              const updatedEconomy = prev.economy.map(
                                (item, idx) => {
                                  if (idx === ecIndex) {
                                    return {
                                      ...item,
                                      tjeneste: e.target.value,
                                    };
                                  }
                                  return item;
                                }
                              );
                              return {
                                ...prev,
                                economy: updatedEconomy,
                              };
                            });
                          }}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          disabled={dis}
                          style={{ border: 0, width: "100%" }}
                          defaultValue={ec.tema}
                          onChange={(e) => {
                            setData((prev) => {
                              const updatedEconomy = prev.economy.map(
                                (item, idx) => {
                                  if (idx === ecIndex) {
                                    return {
                                      ...item,
                                      tema: e.target.value,
                                    };
                                  }
                                  return item;
                                }
                              );
                              return {
                                ...prev,
                                economy: updatedEconomy,
                              };
                            });
                          }}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          disabled={dis}
                          style={{ border: 0, width: "100%" }}
                          defaultValue={ec.e1}
                          onChange={(e) => {
                            setData((prev) => {
                              const updatedEconomy = prev.economy.map(
                                (item, idx) => {
                                  if (idx === ecIndex) {
                                    return {
                                      ...item,
                                      e1: e.target.value,
                                    };
                                  }
                                  return item;
                                }
                              );
                              return {
                                ...prev,
                                economy: updatedEconomy,
                              };
                            });
                          }}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          disabled={dis}
                          style={{ border: 0, width: "100%" }}
                          defaultValue={ec.e2}
                          onChange={(e) => {
                            setData((prev) => {
                              const updatedEconomy = prev.economy.map(
                                (item, idx) => {
                                  if (idx === ecIndex) {
                                    return {
                                      ...item,
                                      e2: e.target.value,
                                    };
                                  }
                                  return item;
                                }
                              );
                              return {
                                ...prev,
                                economy: updatedEconomy,
                              };
                            });
                          }}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          disabled={dis}
                          style={{ border: 0, width: "100%" }}
                          defaultValue={ec.e3}
                          onChange={(e) => {
                            setData((prev) => {
                              const updatedEconomy = prev.economy.map(
                                (item, idx) => {
                                  if (idx === ecIndex) {
                                    return {
                                      ...item,
                                      e3: e.target.value,
                                    };
                                  }
                                  return item;
                                }
                              );
                              return {
                                ...prev,
                                economy: updatedEconomy,
                              };
                            });
                          }}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          disabled={dis}
                          style={{ border: 0, width: "100%" }}
                          defaultValue={ec.e4}
                          onChange={(e) => {
                            setData((prev) => {
                              const updatedEconomy = prev.economy.map(
                                (item, idx) => {
                                  if (idx === ecIndex) {
                                    return {
                                      ...item,
                                      e4: e.target.value,
                                    };
                                  }
                                  return item;
                                }
                              );
                              return {
                                ...prev,
                                economy: updatedEconomy,
                              };
                            });
                          }}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          disabled={dis}
                          style={{ border: 0, width: "100%" }}
                          defaultValue={ec.e5}
                          onChange={(e) => {
                            setData((prev) => {
                              const updatedEconomy = prev.economy.map(
                                (item, idx) => {
                                  if (idx === ecIndex) {
                                    return {
                                      ...item,
                                      e5: e.target.value,
                                    };
                                  }
                                  return item;
                                }
                              );
                              return {
                                ...prev,
                                economy: updatedEconomy,
                              };
                            });
                          }}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          disabled={dis}
                          style={{ border: 0, width: "100%" }}
                          defaultValue={ec.e6}
                          onChange={(e) => {
                            setData((prev) => {
                              const updatedEconomy = prev.economy.map(
                                (item, idx) => {
                                  if (idx === ecIndex) {
                                    return {
                                      ...item,
                                      e6: e.target.value,
                                    };
                                  }
                                  return item;
                                }
                              );
                              return {
                                ...prev,
                                economy: updatedEconomy,
                              };
                            });
                          }}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          disabled={dis}
                          style={{ border: 0, width: "100%" }}
                          defaultValue={ec.e7}
                          onChange={(e) => {
                            setData((prev) => {
                              const updatedEconomy = prev.economy.map(
                                (item, idx) => {
                                  if (idx === ecIndex) {
                                    return {
                                      ...item,
                                      e7: e.target.value,
                                    };
                                  }
                                  return item;
                                }
                              );
                              return {
                                ...prev,
                                economy: updatedEconomy,
                              };
                            });
                          }}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          disabled={dis}
                          style={{ border: 0, width: "100%" }}
                          defaultValue={ec.e8}
                          onChange={(e) => {
                            setData((prev) => {
                              const updatedEconomy = prev.economy.map(
                                (item, idx) => {
                                  if (idx === ecIndex) {
                                    return {
                                      ...item,
                                      e8: e.target.value,
                                    };
                                  }
                                  return item;
                                }
                              );
                              return {
                                ...prev,
                                economy: updatedEconomy,
                              };
                            });
                          }}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          disabled={dis}
                          style={{ border: 0, width: "100%" }}
                          defaultValue={ec.e9}
                          onChange={(e) => {
                            setData((prev) => {
                              const updatedEconomy = prev.economy.map(
                                (item, idx) => {
                                  if (idx === ecIndex) {
                                    return {
                                      ...item,
                                      e9: e.target.value,
                                    };
                                  }
                                  return item;
                                }
                              );
                              return {
                                ...prev,
                                economy: updatedEconomy,
                              };
                            });
                          }}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          disabled={dis}
                          style={{ border: 0, width: "100%" }}
                          defaultValue={ec.e10}
                          onChange={(e) => {
                            setData((prev) => {
                              const updatedEconomy = prev.economy.map(
                                (item, idx) => {
                                  if (idx === ecIndex) {
                                    return {
                                      ...item,
                                      e10: e.target.value,
                                    };
                                  }
                                  return item;
                                }
                              );
                              return {
                                ...prev,
                                economy: updatedEconomy,
                              };
                            });
                          }}
                        />
                      </td>
                      <td>
                        <input
                          style={{ width: "100%" }}
                          type="text"
                          disabled
                          value={convertNumber(
                            parseInt(
                              (parseInt(data?.economy?.[ecIndex]?.e1) || 0) +
                                (parseInt(data?.economy?.[ecIndex]?.e2) || 0) +
                                (parseInt(data?.economy?.[ecIndex]?.e3) || 0) +
                                (parseInt(data?.economy?.[ecIndex]?.e4) || 0) +
                                (parseInt(data?.economy?.[ecIndex]?.e5) || 0) +
                                (parseInt(data?.economy?.[ecIndex]?.e6) || 0) +
                                (parseInt(data?.economy?.[ecIndex]?.e7) || 0) +
                                (parseInt(data?.economy?.[ecIndex]?.e8) || 0) +
                                (parseInt(data?.economy?.[ecIndex]?.e9) || 0) +
                                (parseInt(data?.economy?.[ecIndex]?.e10) || 0)
                            )
                          )}
                        />
                      </td>

                      <td>
                        <input
                          type="text"
                          disabled={dis}
                          style={{ border: 0, width: "100%" }}
                          defaultValue={ec.lineComment}
                          onChange={(e) => {
                            setData((prev) => {
                              const updatedEconomy = prev.economy.map(
                                (item, idx) => {
                                  if (idx === ecIndex) {
                                    return {
                                      ...item,
                                      lineComment: e.target.value,
                                    };
                                  }
                                  return item;
                                }
                              );
                              return {
                                ...prev,
                                economy: updatedEconomy,
                              };
                            });
                          }}
                        />
                      </td>
                    </tr>
                  );
                })}
              </>
            )}
            {phases?.length > 0 && (
              <tr>
                <td className="bg-transparent">&nbsp;</td>
                <td className="bg-transparent">&nbsp;</td>
                <td className="bg-transparent">&nbsp;</td>
                <td className="bg-transparent p-0 pt-3">
                  <FaseDisplay data={phases} year={data?.economy?.[0]?.year} />
                </td>
                <td className="bg-transparent p-0 pt-3">
                  <FaseDisplay
                    data={phases}
                    year={parseInt(data?.economy?.[0]?.year) + 1}
                  />
                </td>
                <td className="bg-transparent p-0 pt-3">
                  <FaseDisplay
                    data={phases}
                    year={parseInt(data?.economy?.[0]?.year) + 2}
                  />
                </td>
                <td className="bg-transparent p-0 pt-3">
                  <FaseDisplay
                    data={phases}
                    year={parseInt(data?.economy?.[0]?.year) + 3}
                  />
                </td>
                <td className="bg-transparent p-0 pt-3">
                  <FaseDisplay
                    data={phases}
                    year={parseInt(data?.economy?.[0]?.year) + 4}
                  />
                </td>
                <td className="bg-transparent p-0 pt-3">
                  <FaseDisplay
                    data={phases}
                    year={parseInt(data?.economy?.[0]?.year) + 5}
                  />
                </td>
                <td className="bg-transparent p-0 pt-3">
                  <FaseDisplay
                    data={phases}
                    year={parseInt(data?.economy?.[0]?.year) + 6}
                  />
                </td>
                <td className="bg-transparent p-0 pt-3">
                  <FaseDisplay
                    data={phases}
                    year={parseInt(data?.economy?.[0]?.year) + 7}
                  />
                </td>
                <td className="bg-transparent p-0 pt-3">
                  <FaseDisplay
                    data={phases}
                    year={parseInt(data?.economy?.[0]?.year) + 8}
                  />
                </td>
                <td className="bg-transparent p-0 pt-3">
                  <FaseDisplay
                    data={phases}
                    year={parseInt(data?.economy?.[0]?.year) + 9}
                  />
                </td>
                <td className="bg-transparent">&nbsp;</td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>

      {dis && changesMade && (
        <div className="p-1" style={{ border: "1px #000 dotted" }}>
          <h6 className="bg-warning p-1">(!) Godkjent endring</h6>
          <Table variant="">
            <thead>
              <tr>
                <th>Konto</th>
                <th>Tjeneste</th>
                <th>Tema</th>
                {data?.economy?.length > 0 && (
                  <>
                    {Array.from({ length: 10 }, (_, index) => {
                      return (
                        <th key={index}>
                          {parseInt(data.economy[0].year) + index}
                        </th>
                      );
                    })}
                  </>
                )}
              </tr>
            </thead>
            <tbody>
              {data?.changes?.length > 0 && (
                <>
                  {data.changes.map((ec, ecIndex) => {
                    return (
                      <tr key={ecIndex}>
                        <td>
                          <input
                            type="text"
                            disabled={dis}
                            style={{ border: 0, width: "100%" }}
                            defaultValue={ec.konto}
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            disabled={dis}
                            style={{ border: 0, width: "100%" }}
                            defaultValue={ec.tjeneste}
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            disabled={dis}
                            style={{ border: 0, width: "100%" }}
                            defaultValue={ec.tema}
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            disabled={dis}
                            style={{ border: 0, width: "100%" }}
                            defaultValue={ec.e1}
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            disabled={dis}
                            style={{ border: 0, width: "100%" }}
                            defaultValue={ec.e2}
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            disabled={dis}
                            style={{ border: 0, width: "100%" }}
                            defaultValue={ec.e3}
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            disabled={dis}
                            style={{ border: 0, width: "100%" }}
                            defaultValue={ec.e4}
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            disabled={dis}
                            style={{ border: 0, width: "100%" }}
                            defaultValue={ec.e5}
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            disabled={dis}
                            style={{ border: 0, width: "100%" }}
                            defaultValue={ec.e6}
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            disabled={dis}
                            style={{ border: 0, width: "100%" }}
                            defaultValue={ec.e7}
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            disabled={dis}
                            style={{ border: 0, width: "100%" }}
                            defaultValue={ec.e8}
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            disabled={dis}
                            style={{ border: 0, width: "100%" }}
                            defaultValue={ec.e9}
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            disabled={dis}
                            style={{ border: 0, width: "100%" }}
                            defaultValue={ec.e10}
                          />
                        </td>
                      </tr>
                    );
                  })}
                </>
              )}
            </tbody>
          </Table>
        </div>
      )}

      {!dis && (
        <div className="d-flex justify-content-end">
          <div>
            <Button
              size="sm"
              variant="danger"
              onClick={() => {
                setData((prev) => {
                  // Sjekk om economy-arrayet eksisterer og har minst ett element
                  if (!prev.economy || prev.economy.length <= 1) {
                    alert("Kan ikke slette siste linje");
                    return prev;
                  }

                  // Lag en ny array uten det siste elementet
                  const updatedEconomy = prev.economy.slice(0, -1);

                  // Returner den nye tilstanden med oppdatert economy-array
                  return {
                    ...prev,
                    economy: updatedEconomy,
                  };
                });
              }}
            >
              Slett siste linje
            </Button>
            <Button
              size="sm"
              variant="info"
              style={{ marginLeft: 10 }}
              onClick={() =>
                setData((prev) => {
                  // Hent den siste linjen i projectInfo.economy
                  let lastLine = prev.economy[prev.economy.length - 1];

                  // Opprett en ny linje med de samme nøklene, men med tomme verdier eller spesifikke verdier
                  let emptyLine = {};
                  for (let key in lastLine) {
                    if (key === "konto") {
                      emptyLine[key] = "02300";
                    } else if (
                      [
                        "parent",
                        "type_id",
                        "version",
                        "year",
                        "pnr",
                        "pname",
                        "tjeneste",
                      ].includes(key)
                    ) {
                      emptyLine[key] = lastLine[key];
                    } else {
                      emptyLine[key] = "";
                    }
                  }

                  // Legg til den nye linjen på slutten av arrayen
                  let updatedEconomy = [...prev.economy, emptyLine];

                  // Returner den oppdaterte state
                  return {
                    ...prev,
                    economy: updatedEconomy,
                  };
                })
              }
            >
              Legg til linje
            </Button>
          </div>
        </div>
      )}
      <hr />

      {parseInt(data?.phaseReport) ? (
        <>
          <h6>Faserapportering</h6>
          <Table>
            <thead>
              <tr>
                <th>&nbsp;</th>
                <th>Skisse/forprosjekt</th>
                <th>Detaljprosjektering</th>
                <th>Utførelse</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Start</td>
                <td>
                  <input
                    type="date"
                    disabled={dis}
                    defaultValue={
                      phases?.find((p) => p.phase === "skisse/forprosjekt")
                        ?.start || ""
                    }
                    onChange={(e) => {
                      const d = e.target.value; // Dato
                      const existingPhaseIndex = phases.findIndex(
                        (p) => p.phase === "skisse/forprosjekt"
                      );
                      if (existingPhaseIndex !== -1) {
                        // Oppdater sluttdato for fasen
                        const updatedPhases = [...phases];
                        updatedPhases[existingPhaseIndex] = {
                          ...updatedPhases[existingPhaseIndex],
                          start: d,
                        };
                        setPhases(updatedPhases);
                      } else {
                        // Legg til fase hvis utførelse ikke finnes
                        const newPhase = {
                          phase: "skisse/forprosjekt",
                          start: d,
                          slutt: d,
                        };
                        setPhases([...phases, newPhase]);
                      }
                    }}
                  />
                </td>
                <td>
                  <input
                    type="date"
                    disabled={dis}
                    defaultValue={
                      phases?.find((p) => p.phase === "detaljprosjektering")
                        ?.start || ""
                    }
                    onChange={(e) => {
                      const d = e.target.value; // Dato
                      const existingPhaseIndex = phases.findIndex(
                        (p) => p.phase === "detaljprosjektering"
                      );
                      if (existingPhaseIndex !== -1) {
                        // Oppdater sluttdato for fasen
                        const updatedPhases = [...phases];
                        updatedPhases[existingPhaseIndex] = {
                          ...updatedPhases[existingPhaseIndex],
                          start: d,
                        };
                        setPhases(updatedPhases);
                      } else {
                        // Legg til fase hvis utførelse ikke finnes
                        const newPhase = {
                          phase: "detaljprosjektering",
                          start: d,
                          slutt: d,
                        };
                        setPhases([...phases, newPhase]);
                      }
                    }}
                  />
                </td>
                <td>
                  <input
                    type="date"
                    disabled={dis}
                    defaultValue={
                      phases?.find((p) => p.phase === "utførelse")?.start || ""
                    }
                    onChange={(e) => {
                      const d = e.target.value; // Dato
                      const existingPhaseIndex = phases.findIndex(
                        (p) => p.phase === "utførelse"
                      );
                      if (existingPhaseIndex !== -1) {
                        // Oppdater sluttdato for fasen
                        const updatedPhases = [...phases];
                        updatedPhases[existingPhaseIndex] = {
                          ...updatedPhases[existingPhaseIndex],
                          start: d,
                        };
                        setPhases(updatedPhases);
                      } else {
                        // Legg til fase hvis utførelse ikke finnes
                        const newPhase = {
                          phase: "utførelse",
                          start: d,
                          slutt: d,
                        };
                        setPhases([...phases, newPhase]);
                      }
                    }}
                  />
                </td>
              </tr>
              <tr>
                <td>Slutt</td>
                <td>
                  <input
                    type="date"
                    disabled={dis}
                    defaultValue={
                      phases?.find((p) => p.phase === "skisse/forprosjekt")
                        ?.slutt || ""
                    }
                    onChange={(e) => {
                      const d = e.target.value; // Dato
                      const existingPhaseIndex = phases.findIndex(
                        (p) => p.phase === "skisse/forprosjekt"
                      );
                      if (existingPhaseIndex !== -1) {
                        // Oppdater sluttdato for fasen
                        const updatedPhases = [...phases];
                        updatedPhases[existingPhaseIndex] = {
                          ...updatedPhases[existingPhaseIndex],
                          slutt: d,
                        };
                        setPhases(updatedPhases);
                      } else {
                        // Legg til fase hvis utførelse ikke finnes
                        const newPhase = {
                          phase: "skisse/forprosjekt",
                          start: d,
                          slutt: d,
                        };
                        setPhases([...phases, newPhase]);
                      }
                    }}
                  />
                </td>
                <td>
                  <input
                    type="date"
                    disabled={dis}
                    defaultValue={
                      phases?.find((p) => p.phase === "detaljprosjektering")
                        ?.slutt || ""
                    }
                    onChange={(e) => {
                      const d = e.target.value; // Dato
                      const existingPhaseIndex = phases.findIndex(
                        (p) => p.phase === "detaljprosjektering"
                      );
                      if (existingPhaseIndex !== -1) {
                        // Oppdater sluttdato for fasen
                        const updatedPhases = [...phases];
                        updatedPhases[existingPhaseIndex] = {
                          ...updatedPhases[existingPhaseIndex],
                          slutt: d,
                        };
                        setPhases(updatedPhases);
                      } else {
                        // Legg til fase hvis utførelse ikke finnes
                        const newPhase = {
                          phase: "detaljprosjektering",
                          start: d,
                          slutt: d,
                        };
                        setPhases([...phases, newPhase]);
                      }
                    }}
                  />
                </td>
                <td>
                  <input
                    type="date"
                    disabled={dis}
                    defaultValue={
                      phases?.find((p) => p.phase === "utførelse")?.slutt || ""
                    }
                    onChange={(e) => {
                      const d = e.target.value; // Dato
                      const existingPhaseIndex = phases.findIndex(
                        (p) => p.phase === "utførelse"
                      );
                      if (existingPhaseIndex !== -1) {
                        // Oppdater sluttdato for fasen
                        const updatedPhases = [...phases];
                        updatedPhases[existingPhaseIndex] = {
                          ...updatedPhases[existingPhaseIndex],
                          slutt: d,
                        };
                        setPhases(updatedPhases);
                      } else {
                        // Legg til fase hvis utførelse ikke finnes
                        const newPhase = {
                          phase: "utførelse",
                          start: d,
                          slutt: d,
                        };
                        setPhases([...phases, newPhase]);
                      }
                    }}
                  />
                </td>
              </tr>
            </tbody>
          </Table>
          <hr />
        </>
      ) : null}

      <h6>Kommentar</h6>

      {data?.economy?.length > 0 && (
        <textarea
          disabled={dis}
          style={{
            width: "100%",
            height: 180,
            borderRadius: 5,
            padding: 2.5,
            fontSize: 18,
          }}
          defaultValue={data.economy[0].comment}
          onChange={(e) => {
            const newComment = e.target.value; // Hent den nye kommentaren fra hendelsen

            setData((prev) => {
              // Sjekk om economy-arrayet eksisterer og har minst ett element
              if (!prev.economy || prev.economy.length === 0) {
                return prev;
              }

              // Lag en kopi av economy-arrayet
              const updatedEconomy = [...prev.economy];

              // Oppdater comment-feltet til det første elementet
              updatedEconomy[0] = {
                ...updatedEconomy[0],
                comment: newComment,
              };

              // Returner den nye tilstanden med oppdatert economy-array
              return {
                ...prev,
                economy: updatedEconomy,
              };
            });
          }}
        />
      )}
      {!dis && (
        <>
          {!reportClosed && (
            <>
              <hr />
              <div className="d-flex align-items-end justify-content-end">
                <Button
                  onClick={() => saveKladd()}
                  variant="warning"
                  style={{ marginRight: 15 }}
                >
                  Lagre kladd
                </Button>
                <Button onClick={() => saveReport()} variant="success">
                  Send rapport
                </Button>
              </div>
            </>
          )}
        </>
      )}
    </>
  );
};
