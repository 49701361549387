import React, { useEffect, useState } from "react";
import { Table, Breadcrumb, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import LogUser from "../../hooks/log";

export default function Users() {
  const [users, setUsers] = useState([]);
  const [types, setTypes] = useState([]);
  const [newUserActive, setNewUserActive] = useState(false);

  // New User Info
  const [userName, setUserName] = useState("");
  const [userMail, setUserMail] = useState("");
  const [userPw, setUserPw] = useState("");
  const [userType, setUserType] = useState("");

  useEffect(() => {
    getData();
  }, []);
  const getData = async () => {
    try {
      const res = await fetch("https://prosjekt.tkva.no/api/admin/users.php", {
        credentials: "include",
      });
      const data = await res.json();
      setUsers(data.users);
      setTypes(data.types);
    } catch (err) {
      return;
    }
  };

  const newUser = async () => {
    const postData = {
      action: "ADD_USER",
      name: userName,
      mail: userMail,
      password: userPw,
      type: userType,
    };

    try {
      const res = await fetch("https://prosjekt.tkva.no/api/admin/users.php", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(postData),
        credentials: "include",
      });
      if (!res.ok) {
        alert("Noe gikk galt.. dessverre");
      } else {
        getData();
        setNewUserActive(false);
        alert(userName + " er lagt til systemet");
        LogUser(
          "Brukere",
          "Brukeren har lagt til ny bruker i systemet, " + userName
        );
      }
    } catch (err) {
      alert("Noe gikk galt: " + err.message);
    }
  };

  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link to="../admin">Admin</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item active>
          <Link to="../admin/users">Brukere</Link>
        </Breadcrumb.Item>
      </Breadcrumb>
      <div className="row">
        <div className="col-sm-8">
          <div className="d-flex justify-content-between mb-2">
            <h5>Brukere</h5>
            {newUserActive ? (
              <Button
                size="sm"
                variant="primary"
                onClick={() => setNewUserActive(false)}
              >
                Tilbake
              </Button>
            ) : (
              <Button
                size="sm"
                variant="success"
                onClick={() => setNewUserActive(true)}
              >
                Ny bruker
              </Button>
            )}
          </div>
          {newUserActive ? (
            <>
              <h6>Legg til ny bruker</h6>
              <div className="content">
                <>
                  <div className="input-group mb-3">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Fullt navn"
                      aria-label="Username"
                      aria-describedby="basic-addon1"
                      onChange={(e) => setUserName(e.target.value)}
                    />
                  </div>
                  <div className="input-group mb-3">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="E-post"
                      aria-label="Username"
                      aria-describedby="basic-addon1"
                      onChange={(e) => setUserMail(e.target.value)}
                    />
                  </div>
                  <div className="input-group mb-3">
                    <input
                      type="password"
                      className="form-control"
                      placeholder="Passord"
                      aria-label="Password"
                      aria-describedby="basic-addon1"
                      onChange={(e) => setUserPw(e.target.value)}
                    />
                  </div>
                  <hr />
                  <div className="input-group mb-3">
                    <select
                      className="form-select"
                      aria-label="Default select example"
                      onChange={(e) => setUserType(e.target.value)}
                    >
                      <option selected="">Velg tilhørighet</option>
                      {types?.map((type, typeKey) => {
                        return (
                          <option key={typeKey} value={type.details}>
                            {type.description}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <hr />
                  <Button size="sm" variant="success" onClick={() => newUser()}>
                    Opprett bruker
                  </Button>
                </>
              </div>
            </>
          ) : (
            <div
              className="content overflow-auto"
              style={{ maxHeight: "80vh" }}
            >
              {users?.map((user, userKey) => {
                return (
                  <div className="card m-2" key={userKey}>
                    <div className="card-body">
                      <div className="d-flex justify-content-between">
                        <div>
                          <h6>{user.name}</h6>
                          <span>{user.email}</span>
                        </div>
                        <div
                          className="d-flex flex-column"
                          style={{ minWidth: "180px" }}
                        >
                          <span>{user.typeName}</span>
                          <span>{user.lastLogon}</span>
                          <span>
                            <Link to={"../admin/users/logs/" + user.id}>
                              <i className="bi bi-info-lg" /> Logg
                            </Link>
                          </span>
                          <span>
                            <Link to={"../admin/users/access/" + user.id}>
                              <i className="bi bi-key-fill" /> Tilganger
                            </Link>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </div>
    </>
  );
}
